.footer {
  display: flex;
  //padding-top: size(160px);
  padding-bottom: size(80px);

  @include viewport--md {
    flex-wrap: wrap;
  }

  @include viewport--md {
    padding-bottom: 56px;
  }

  &__copy-right {
    width: 23%;
    min-width: size(305px);
    margin-right: size(35px);
    display: flex;
    flex-direction: column;

    @media(max-width: 1290px) {
      width: 15%;
      min-width: size(274px);
      margin-right: 2.8%;
    }

    @include viewport--md {
      width: 100%;
      margin-right: 0;
      margin-top: 77px;
      order: 4;
      flex-direction: row;
    }

    @include viewport--sm {
      margin-top: 50px;
      flex-direction: column;
    }
  }

  &__logo {
    margin-top: size(5px);
    svg {
      width: size(270px);
      height: size(70px);
    }

    a & {
      cursor: pointer;
    }

    @include viewport--md {
      width: 47.15%;
      margin-right: 5.11%;
    }

    @include viewport--sm {
      width: 100%;
      margin-right: 0;
      margin-bottom: 21px;

      svg {
        width: 209px;
        height: 53px;
      }
    }
  }

  &__copy-right-wrap {
    margin-top: auto;
    font-family: $font-family--main;
    font-size: size(16px);
    font-weight: 400;
    line-height: size(24px);
    color: $color-default-black;

    p {
      margin: 0;
    }

    a {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-orange;
        }
      }
    }

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__container {
    width: 74.3%;
    display: grid;
    grid-template-columns: minmax(272px, 30.4%) 30.4% 30.4%;
    grid-template-rows: auto;
    grid-template-areas: "contacts address lang";
    //grid-template: "contacts address lang";
    column-gap: size(42px); //36px;

    @media(max-width: 1290px) {
      grid-template-columns: minmax(250px, 31%) 31% 31%;
      width: 82.2%;
      column-gap: 30px;
    }

    @include viewport--md {
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-template:
        "address contacts"
        "address lang";

      column-gap: 26px;
    }

    @include viewport--sm {
      grid-template-columns: 100%;
      grid-template:
        "address"
        "contacts"
        "lang";
    }
  }

  &__contact {
    grid-area: contacts;
    position: relative;
    //min-width: size(265px);
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    @include viewport--md {
      order: 2;
      margin-bottom: 31px;
    }

    @include viewport--sm {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__item {
    margin-bottom: size(32px);

    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--sm {
      margin-bottom: 17px;
    }
  }

  &__link {
    font-family: $font-family--main;
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__address-wrap {
    grid-area: address;
    position: relative;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    list-style: none;

    @include viewport--md {
      order: 1;
    }

    @include viewport--sm {
      width: 100%;
      margin-right: 0;
      margin-bottom: 26px;
    }
  }

  &__address {
    font-family: $font-family--main;
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);
    margin-bottom: size(31px);

    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }

  &__lang {
    grid-area: lang;

    @include viewport--sm {
      width: 100%;
    }
  }

  &__btn {
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    font-family: $font-family--main;
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);
    text-align: left;

    border: none;
    background-color: $color-transparent;
    color: $color-default-black;
    outline: none;
    cursor: pointer;

    svg {
      width: size(15px);
      height: size(14px);
      margin-left: size(5px);
      color: $color-default-black;
      align-self: center;
      flex-shrink: 0;
    }

    span {
      display: flex;
      flex-wrap: nowrap;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;

        svg {
          color: $color-orange;
        }
      }
    }

    @media(max-width: 1150px) {
      flex-wrap: wrap;
    }

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
