.progress-bar {
  position: fixed;
  bottom: size(10px);
  right: size(40px);
  padding: 0;
  //z-index: 3;
  opacity: 0;
  visibility: hidden;
  z-index: 2147483645;

  @include viewport--md {
    right: 32px;
  }

  @include viewport--sm {
    right: 16px;
  }

  svg {
    width: size(30px);
    height: size(30px);
    transition: opacity $transition, width 0.1s ease, height 0.1s ease;

    &:nth-child(2) {
      width: 0;
      height: 0;
      opacity: 0;
    }

    &:nth-child(3) {
      width: 73px;
      height: 73px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: stroke-dashoffset $transition;
    }
  }

  span {
    width: 0;
    height: 100%;
    display: inline-block;
    background-color: $color-orange;
  }

  &.active {
    svg {
      &:first-child {
        width: 0;
        height: 0;
        opacity: 0;
      }

      &:nth-child(2) {
        width: size(30px);
        height: size(30px);
        opacity: 1;
      }
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &__btn {
    width: size(76px);
    height: size(76px);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-default-white;
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }

  &__list-wrap {
    opacity: 0;
    position: absolute;
    width: 312px;
    bottom: size(96px);
    right: 0;
    pointer-events: none;
    background-color: $color-default-white;
    border: 2px solid $color-whisper;
    max-height: calc(100vh - 165px);
    max-height: calc(100 * var(--vh, 1vh) - 165px);
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .progress-bar.active & {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
    }

    @media (max-width: 350px) {
      width: calc(100vw - 32px);
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: size(36px) size(30px) size(38px) size(37px);
    background-color: $color-default-white;
    //border: 2px solid $color-whisper;

  }

  &__item {
    margin-bottom: size(32px);
    font-size: size(20px);
    font-weight: 600;
    line-height: size(24px);

    &:last-child {
      margin-bottom: 0;
    }

    a {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-silver;
        }
      }
    }
  }
}
