.main-mission {
  padding-top: size(136px);
  padding-bottom: size(127px);

  @include viewport--md {
    padding-top: size(138px);
    padding-bottom: size(133px);
  }

  @include viewport--sm {
    padding-top: size(94px);
    padding-bottom: size(80px);
  }

  &__slider {
    margin-bottom: size(84px);
    height: size(700px);

    @include viewport--sm {
      height: 344px;
      margin-bottom: 60px;
    }

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 65%;
    padding-left: size(114px);

    @include viewport--md {
      width: 100%;
      padding-left: 0;
      margin-bottom: 78px;
    }

    @include viewport--sm {
      margin-bottom: 55px;
    }
  }

  &__text {
    margin: 0;
    font-size: size(32px);
    line-height: size(44px);

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
