.main-nav {
  position: relative;
  min-height: size(68px);
  margin-left: size(15px);

  @include viewport--md {
    min-height: auto;
    margin-left: 0;
    position: fixed;
    right: 16px;
    padding: 58px 20px 28px;
    top: 0;
    background-color: $color-default-white;
    z-index: 4;
  }

  @include viewport--sm {
    min-height: auto;
    margin-left: 0;
    position: fixed;
    right: 0;
    padding: 21px 16px 21px 18px;
    top: 0;
    background-color: $color-default-white;
    z-index: 4;
  }

  &__wrapper {
    display: flex;
    position: relative;
    align-items: flex-start;
    padding: size(10px) 0 size(31px);

    @include viewport--md {
      display: none;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: size(4px);
      background-color: $color-whisper;
      bottom: 0;
    }

    &.search-open {
      width: calc(100% + #{size(56px)});
      left: size(-56px);
      padding: size(6px) 0 size(42px);
      min-height: size(70px);

      @include viewport--md {
        left: 0;
      }

      .main-nav__list {
        opacity: 0;
        z-index: -1;
      }

      .main-nav__search {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .main-nav__navigation {
        position: absolute;
        margin-left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        height: size(32px);

        @include viewport--md {
          height: 76px;
        }

        @include viewport--sm {
          height: 56px;
        }

        input {
          width: 100%;
          padding: 0;
          border: none;
          font-weight: 600;
          font-size: size(16px);
          line-height: size(16px);
          color: $color-silver;
          box-shadow: none;
          background-color: unset;
          min-height: size(20px);
          height: size(88px);
        }

        @media screen and (-webkit-min-device-pixel-ratio: 0) {
          input:focus {
            font-size: 16px;
          }
        }

        input:focus {
          outline: 0;
        }

        input::placeholder {
          color: $color-silver;
          vertical-align: center;
        }

        form {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        .main-nav__navigation-toggle {
          svg {
            color: $color-silver;
          }

          svg:first-child {
            display: none;
          }

          svg:last-child {
            display: flex;
          }
        }
      }
    }
  }

  &__navigation {
    margin-left: size(32px);

    form {
      display: none;
    }

    input {
      width: 100%;
      padding: 0;
      border: none;
      font-weight: 600;
      font-size: size(20px);
      line-height: size(24px);
    }
  }

  &__navigation-toggle {
    svg {
      color: $color-silver;
    }

    svg:first-child {
      width: size(24px);
      height: size(24px);
      position: relative;
      top: size(2px);

      @include viewport--md {
        top: 0;
      }
    }

    svg:last-child {
      display: none;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      svg {
        color: $color-default-black;
      }
    }
  }

  &__breadcrumbs {
    display: none;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-bottom: size(-10px);
    list-style: none;

    color: $color-default-black;

    &:hover > .main-nav__item:not(:hover) {
      color: $color-silver;
    }
  }

  &__item {
    margin-right: size(31px);
    display: flex;
    align-items: center;
    margin-bottom: size(10px);

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__link {
    font-weight: 600;
    font-size: size(20px);
    line-height: size(24px);
    color: $color-silver;

    &:hover {
      color: $color-default-black;
    }

    &--active {
      color: $color-default-black;
    }
  }

  &__submenu {

    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $color-default-white;
    align-self: flex-start;
    pointer-events: auto;
    color: $color-default-black;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $color-orange;
    }

    &:hover > .main-nav__subitem:not(:hover) {
      color: $color-silver;
    }

    & > .main-nav__subitem:hover {
      color: $color-default-black;
    }

    &.open,
    &.clicked {
      display: flex;
      flex-direction: column;
    }
  }

  &__inner {
    display: none;
    top: 0;
    pointer-events: none;
  }

  &__bottom {
    display: none;
  }

  .header.open & {
    & {
      width: 100%;
      padding: 0;
      height: 100%;
      color: $color-default-white;
      margin-left: 0;
    }

    &__wrapper {
      width: 100%;
      height: 100%;
      padding: 0;

      &::after {
        content: none;
      }
    }

    &__bottom.show {
      display: block;
    }

    &__list {
      flex-direction: column;
      position: relative;
      width: 25.7%;
      height: 100%;
    }

    &__link--active {
      color: $color-silver;
    }

    &__sublink.active {
      color: $color-default-black;
    }

    &__item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0;
      padding-bottom: 28px;

      &.clicked {
        .main-nav__link {
          color: $color-default-black;
        }

        .main-nav__bottom.show {
          display: block;
        }
      }

      &.open,
      &.clicked {
        .main-nav__link {
          color: $color-default-black;
        }

        .main-nav__inner.open,
        .main-nav__inner.clicked {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 133.5%;
          height: 100%;
          left: 100%;
          top: 0;
          pointer-events: initial;

          & .main-nav__submenu .main-nav__item {
            color: $color-default-black;
          }

          &.hover > .main-nav__submenu {
            color: $color-silver;
          }
        }

        .main-nav__inner--3col.open {
          width: 100%;
        }
      }
    }

    &__navigation {
      display: none;

      @include viewport--md {
        display: flex;
      }
    }

    &__link {
      font-weight: 600;
      font-size: size(32px);
      line-height: size(36px);

      &:hover {
        color: $color-default-black;
      }
    }

    &__subitem {
      width: fit-content;
      pointer-events: auto;

      @include viewport--md {
        padding-bottom: 32px;
      }

      @include viewport--sm {
        padding-bottom: 24px;
      }
    }

    &__bottom {
      position: absolute;
      bottom: 0;
    }

    &__bottom-name {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $color-default-black;
    }

    &__sublink {
      color: inherit;
      font-weight: 600;
      font-size: size(32px);
      line-height: size(36px);
      padding-bottom: 28px;
      display: flex;

      @include viewport--md {
        padding-bottom: 0;
      }
    }

    &__subitem.open.clicked {
      color: $color-default-black;
    }

    & .accordion__content {
      display: block;
      max-height: 0;
      visibility: hidden;
      transition: none;

      .main-nav__sublink {
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 0;
      }

      &.active {
        padding-top: size(25px);
        padding-bottom: size(25px);

        @include viewport--md {
          height: 100% !important;
          max-height: none !important;
        }

        .main-nav__subitem {
          padding-bottom: size(8px);

          @include viewport--md {
            padding-bottom: 24px;
          }
        }
      }
    }

    & .accordion__btn.active {
      padding-bottom: 0;
    }

    &__inner.active {
      display: block;
      position: relative;
      visibility: visible;
    }

    &__search {
      display: none;
    }
  }

  .header.header--opened & {
    top: 0;
    width: 100%;

    @include viewport--md {
      position: relative;
      padding: 0;
      right: 0;
    }

    .main-nav__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      padding: 0;

      &::after {
        content: none;
      }
    }

    .main-nav__navigation {
      position: relative;
      margin-left: 0;
      width: calc(100% - #{size(50px)});
      margin-bottom: size(59px);

      @include viewport--md {
        width: calc(100% - 72px);
      }

      @include viewport--sm {
        margin-bottom: 20px;
        width: calc(100% - 53px);
      }

      form {
        display: block;
        width: 100%;

        input {
          background-color: $color-white-smoke;
          padding: size(23px) size(75px) size(21px) size(23px);
          font-size: size(16px);
          line-height: size(24px);
          color: $color-nobel;
          width: 100%;

          @include viewport--md {
            min-height: 76px;
            font-weight: 400;
          }

          @include viewport--sm {
            font-size: 12px;
            line-height: 16px;
            min-height: 56px;
            padding: 12px 30px 12px 11px;
          }
        }

        input::placeholder {
          font-size: size(16px);
          line-height: size(24px);
          color: $color-nobel;

          @include viewport--md {
            font-weight: 400;
          }

          @include viewport--sm {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    .main-nav__navigation-toggle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: size(17px);

      @include viewport--sm {
        right: 6px;
        top: 52.9%;
      }
    }

    .main-nav__toggle {
      position: absolute;

      svg:first-child {
        display: none;
      }

      svg:last-child {
        display: flex;
      }
    }

    .main-nav__list {
      flex-direction: column;
      width: 100%;
      padding-top: size(32px);

      @include viewport--md {
        padding-top: 25px;
      }

      @include viewport--sm {
        padding-top: 18px;
      }
    }

    .main-nav__item {
      margin-bottom: size(32px);
      padding-bottom: 0;

      @include viewport--sm {
        margin-bottom: 24px;
      }
    }

    .main-nav__link {
      font-weight: 600;
      font-size: size(50px);
      line-height: size(60px);
      color: $color-default-black;

      @include viewport--sm {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .main-nav__inner.open {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .header.header--black & {
    &__sublink {
      color: $color-default-black;
    }
  }

  .header.header--opened.open & {
    .main-nav__list {
      background-color: $color-default-white;

      @include viewport--md {
        padding: 32px 36px 0;
      }

      @include viewport--sm {
        padding: 15px 16px 0;
      }

      &::before {
        position: absolute;
        content: "";
        top: size(-1px);
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: calc(100% + #{size(76px)});
        background-color: $color-gainsboro;
        z-index: 2;

        @include viewport--md {
          width: calc(100% + 80px);
        }

        @include viewport--sm {
          width: calc(100% + 32px);
        }
      }
    }

    .main-nav__item {
      width: 100%;
      overflow-x: hidden;

      @include viewport--md {
        margin-bottom: 0;
        opacity: 0;
        pointer-events: none;
      }

      .main-nav__inner {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color-default-white;
        padding-top: size(32px);

        @include viewport--md {
          height: auto;
          padding: 25px 36px 0;
        }

        @include viewport--sm {
          padding: 22px 16px 0;
        }
      }
    }

    .main-nav__item.open {
      @include viewport--md {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .main-nav__sublink {
      font-weight: 600;
      font-size: size(50px);
      line-height: size(60px);
      color: $color-default-black;

      @include viewport--sm {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .main-nav__navigation {
      margin-bottom: size(34px);

      @include viewport--md {
        padding: 40px 36px 0;
      }

      @include viewport--sm {
        margin-bottom: 13px;
        padding: 15px 16px 0;
      }
    }

    .main-nav__breadcrumbs {
      display: flex;

      span {
        font-weight: 500;
        font-size: size(16px);
        line-height: size(24px);
        color: $color-default-black;
        position: relative;

        @include viewport--sm {
          font-size: 12px;
          line-height: 16px;
        }

        &::after {
          content: "/";
          position: relative;
          color: $color-nobel;
          margin: 0 12px;
        }
      }

      span:last-child {
        display: block;
        font-weight: 600;
        font-size: size(50px);
        line-height: size(60px);
        color: $color-nobel;
        margin-top: size(7px);

        @include viewport--sm {
          margin-top: 1px;
          font-size: 32px;
          line-height: 36px;
        }

        &::after {
          content: none;
        }
      }

      span:nth-last-child(2) {
        &::after {
          content: none;
        }
      }

      span:first-child {
        margin-right: -5px;
      }
    }

    .main-nav__breadcrumbs-back.btn {
      flex-shrink: 0;
      width: size(50px);
      align-items: flex-start;

      @include viewport--sm {
        width: 30px;
        padding-top: 12px;
      }

      svg {
        transform: rotate(0);
        width: size(48px);
        height: size(48px);

        @include viewport--sm {
          width: 30px;
          height: 30px;
        }
      }
    }

    .main-nav__breadcrumbs-block {
      margin-left: size(37px);

      @include viewport--sm {
        margin-left: 20px;
      }
    }

    .main-nav__search {
      display: none;
    }
  }

  .header.header--hero & {
    margin-left: 0;
    position: relative;
    right: unset;
    padding: 0;

    .main-nav__list {
      flex-direction: column;
      background-color: $color-default-white;
      color: $color-default-black;
      pointer-events: none;

      &:hover > .main-nav__item:not(:hover) {
        color: $color-silver;
      }
    }

    .main-nav__navigation {
      display: none;

      @include viewport--md {
        display: block;
        height: auto;
      }
    }

    .main-nav__wrapper {
      padding: 0;

      &::after {
        content: none;
      }
    }

    .main-nav__item {
      margin-right: 0;
      padding-bottom: size(12px);
      margin-bottom: 0;
      color: $color-default-black;
      pointer-events: auto;

      @include viewport--md {
        padding-bottom: 0;
        margin-bottom: 32px;
      }

      @include viewport--sm {
        margin-bottom: 24px;
      }
    }

    .main-nav__link {
      color: inherit;

      @include viewport--md {
        font-weight: 600;
        font-size: 50px;
        line-height: 60px;
      }

      @include viewport--sm {
        font-size: 32px;
        line-height: 36px;
      }
    }
  }

  .header.header--hero.header--black & {
    .main-nav__link {
      color: $color-default-black;
    }
  }

  .header.header--hero.open & {
    .main-nav__link {
      color: $color-silver;

      &:hover {
        color: $color-default-black;
      }

      @include viewport--md {
        font-weight: 600;
        font-size: 50px;
        line-height: 60px;
      }

      @include viewport--sm {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .main-nav__item {
      padding-bottom: size(28px);

      @include viewport--md {
        opacity: 0;
        pointer-events: none;
      }

      margin-bottom: 0;
    }

    .main-nav__item.open {
      opacity: 1;
      pointer-events: auto;

      .main-nav__link {
        color: $color-default-black;
      }
    }

    .main-nav__item.clicked {
      .main-nav__link {
        color: $color-default-black;
      }
    }

    .main-nav__subitem.open {
      .main-nav__link {
        color: $color-default-black;
      }
    }
  }
}
