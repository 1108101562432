.main-search {
  padding-top: size(133px);
  padding-bottom: size(136px);

  @include viewport--md {
    padding-top: size(133px);
    padding-bottom: size(138px);
  }

  @include viewport--sm {
    padding-top: size(89px);
    padding-bottom: size(94px);
  }

  .page-title--section {
    margin-bottom: size(85px);

    @include viewport--sm {
      margin-bottom: 45px;
    }
  }

  &__text-block {
    margin: 0 auto;
    width: size(660px);

    @include viewport--md {
      width: 100%;
    }
  }

  &__text {
    margin: 0;
    font-weight: 400;
    font-size: size(50px);
    line-height: size(60px);

    @include viewport--sm {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__search-block {
    margin: size(-6px) auto 0;
    width: size(660px);

    @include viewport--md {
      width: 100%;
    }

    @include viewport--sm {
      margin-top: 62px;
    }
  }
}
