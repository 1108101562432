.reviews {
  display: flex;
  flex-direction: column;

  padding-top: size(60px);
  padding-bottom: size(272px);

  @include viewport--md {
    padding-top: 70px;
    padding-bottom: 268px;
  }

  @include viewport--sm {
    padding-top: 42px;
    padding-bottom: 180px;
  }

  &__title {
    width: 62%;
    margin-bottom: size(74px);

    @include viewport--md {
      width: 100%;
      margin-bottom: 74px;
    }

    @include viewport--sm {
      margin-bottom: 56px;
    }

    @media (max-width: 374px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: minmax(size(392px), auto);
    grid-auto-rows: minmax(size(392px), auto);
    gap: size(40px);

    margin: 0;
    padding: 0;

    list-style: none;

    @include viewport--md {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(360px, auto);
      grid-auto-rows: minmax(360px, auto);
      gap: 36px;
    }

    @include viewport--sm {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(430px, auto);
      grid-auto-rows: minmax(430px, auto);
      gap: 18px;
    }
  }
}
