.page-title {
  margin: 0 0 size(75px);
  font-family: $font-family--main;
  font-size: size(65px);
  font-weight: 600;
  line-height: size(76px);
  color: $color-default-black;
  margin-left: size(-4px);

  @include viewport--md {
    margin-left: size(-5px);
  }

  @include viewport--sm {
    margin-left: size(-3px);
  }

  &--404 {
    color: $color-orange;
  }

  @include viewport--md {
    margin: 0 0 size(77px);
    font-size: 60px;
    line-height: 68px;
  }

  @include viewport--sm {
    margin: 0 0 size(56px);
    font-size: 32px;
    line-height: 36px;
  }

  &--section {
    font-weight: 600;
    font-size: size(50px);
    line-height: size(60px);
    margin-bottom: size(78px);
    max-width: 70%;

    @include viewport--md {
      max-width: 100%;
    }

    @include viewport--sm {
      margin: 0 0 size(56px);
      font-size: 32px;
      line-height: 36px;
      max-width: 90%;
    }

    b {
      color: $color-orange;
    }

    i {
      font-weight: 400;
      font-style: normal;
    }
  }

  &--section-70 {
    margin-bottom: size(70px);


    @include viewport--sm {
      margin: 0 0 size(53px);
    }
  }

  &--section-full {
    max-width: 100%;
  }

  &--section-nomargin {
    margin-bottom: 0;
  }
}
