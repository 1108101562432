.main-business {
  padding-top: size(133px);
  padding-bottom: size(133px);

  @include viewport--sm {
    padding-top: size(88px);
    padding-bottom: size(89px);
  }

  &__heading {
    display: flex;
    margin-bottom: size(186px);

    @include viewport--md {
      margin-bottom: 80px;
      flex-direction: column;
    }

    @include viewport--sm {
      margin-bottom: 52px;
    }
  }

  &__heading-text {
    margin-left: size(38px);
    width: 48.5%;
    flex-shrink: 0;
    padding-top: size(12px);

    @include viewport--md {
      width: 90%;
      margin-left: 0;
      margin-top: 70px;
    }

    @include viewport--sm {
      margin-top: 52px;
      width: 100%;
    }

    p {
      margin: 0;
      font-size: size(24px);
      line-height: size(32px);

      @include viewport--sm {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  &__advantages {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    gap: size(88px) size(40px);

    @include viewport--md {
      grid-template-columns: repeat(2, 1fr);
      gap: 88px 34px;
    }

    @include viewport--sm {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }
  }

  &__advantages-item {
    min-height: size(308px);

    @include viewport--sm {
      min-height: auto;
    }
  }
}
