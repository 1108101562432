.main-partners {
  padding-top: size(130px);
  padding-bottom: size(133px);

  @include viewport--md {
    padding-top: size(135px);
    padding-bottom: size(133px);
  }

  @include viewport--sm {
    padding-top: size(90px);
    padding-bottom: size(90px);
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 size(-20px) size(-40px);
    display: flex;
    flex-wrap: wrap;

    @include viewport--md {
      margin: 0 0 92px;
      flex-direction: column;
    }

    @include viewport--sm {
      margin: 0 0 62px;
    }
  }

  &__item {
    width: calc(100% / 2 - #{size(40px)});
    margin: 0 size(20px) size(40px);
    

    @include viewport--md {
      width: 100%;
      margin: 0 0 36px;
    }

    @include viewport--sm {
      margin: 0 0 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
    
    &:hover {
      .modal-partner__head::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
      }

      .modal-partner__content svg {
        display: block;
        position: absolute;
        bottom: size(17px);
        right: size(20px);
      }
    }

    .modal-partner__content svg {
      display: none;
    }
  }

  &__link {
    width: 100%;
    height: 100%;
  }
}
