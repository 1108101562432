.partners {
  display: flex;
  flex-direction: column;

  padding-top: size(68px);
  padding-bottom: size(272px);

  @include viewport--md {
    padding-top: 70px;
    padding-bottom: 265px;
  }

  @include viewport--sm {
    padding-top: 40px;
    padding-bottom: 180px;
  }

  &__title {
    margin-bottom: size(72px);

    @include viewport--md {
      margin-bottom: 77px;
    }

    @include viewport--sm {
      margin-bottom: 56px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: size(332px) size(160px);
    grid-auto-rows: size(160px);
    gap: size(40px);
    grid-template-areas:
      "a a b b"
      ". . . .";

    margin: 0;
    padding: 0;

    list-style: none;

    @include viewport--md {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 348px) 172px;
      grid-auto-rows: 172px;
      gap: 36px;
      grid-template-areas:
        "a a"
        "b b"
        ". .";
    }

    @include viewport--sm {
      grid-template-rows: repeat(2, 172px) 88px;
      grid-auto-rows: 88px;
      gap: 16px;
    }
  }

  &__item {
    display: flex;

    &--main {
      grid-area: b;

      &:first-of-type {
        grid-area: a;
      }

      .partners__link {
        padding: size(50px) 15%;

        @include viewport--sm {
          padding: size(25px) 15%;
        }
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: size(25px) 15%;

    opacity: 0.7;
    background-color: $color-whisper;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    @include viewport--md {
      padding: 25px 15%;
    }

    @include viewport--sm {
      padding: 15px 15%;
    }

    @media (max-width: 374px) {
      padding: 15px 18px;
    }

    img {
      max-height: 100%;
      object-fit: contain;
    }
  }
}
