.feedback {
  padding-top: size(20px);

  &__title {
    @include viewport--md {
      margin-bottom: 72px;
    }
  }

  &__wrap {
    margin-top: size(-7px);
    padding-bottom: size(129px); //size(159px);
    display: flex;
    flex-wrap: wrap;

    @include viewport--md {
      flex-direction: column;
      padding-bottom: 109px;
    }

    @include viewport--sm {
      padding-bottom: 103px;
    }
  }

  &__text {
    width: calc(100% - 310px);
    margin-bottom: size(23px);
    padding-right: size(20px);
    font-size: size(32px);
    font-weight: 400;
    line-height: size(44px);

    @include viewport--md {
      width: 100%;
      padding-right: 0;
      margin-bottom: 91px;
    }

    @include viewport--sm {
      margin-bottom: 33px;
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__picture {
    position: relative;
    width: 310px;
    height: 310px;
    margin-top: size(-10px);
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }

    @include viewport--md {
      margin-bottom: 32px;
    }

    @include viewport--sm {
      width: 170px;
      height: 170px;
      margin-bottom: 12px;
    }
  }

  &__position {
    margin-bottom: size(90px);
    font-size: size(16px);
    font-weight: 400;
    line-height: size(24px);

    @include viewport--sm {
      max-width: 167px;
      margin-bottom: 33px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }
}
