.blog-list {
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    display: flex;

    margin-bottom: size(40px);

    @include viewport--md {
      flex-direction: column;
      margin-bottom: 92px;

      .page__other-post & {
        margin-bottom: 116px;
      }
    }

    @include viewport--sm {
      margin-bottom: 68px;

      .page__other-post & {
        margin-bottom: 64px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    max-width: 48.5%;
    margin-right: size(40px);

    overflow: hidden;

    @include viewport--md {
      max-width: 100%;
      padding-bottom: 440px;

      margin-right: 0;
      margin-bottom: 30px;

      @include viewport--sm {
        padding-bottom: 344px;
        margin-bottom: 24px;
      }
    }


    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover ~ .blog-list__content,
      &:focus ~ .blog-list__content {
        .blog-list__title {
          color: $color-orange;
        }
      }
    }


    img {
      width: 100%;
      object-fit: cover;

      @include viewport--md {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    width: 48.5%;
    margin-left: auto;
    padding: size(36px) 0 0;

    border-bottom: size(2px) solid $color-whisper;

    @include viewport--md {
      width: auto;
      margin-left: 0;
      padding: 0;
    }
  }

  &__link {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        .blog-list__title {
          color: $color-orange;
        }
      }
    }
  }

  &__title {
    margin: 0;
    margin-bottom: size(28px);
    width: 95%;

    font-weight: 600;
    font-size: size(32px);
    line-height: size(36px);

    @include viewport--md {
      width: 94%;
      margin-bottom: 20px;
    }

    @include viewport--sm {
      width: auto;
    }
  }

  &__text {
    margin: 0;
    margin-bottom: size(20px);

    font-weight: normal;
    font-size: size(24px);
    line-height: size(32px);

    @include viewport--md {
      margin-bottom: 18px;
      width: 94%;
    }

    @include viewport--sm {
      margin-bottom: 20px;

      font-size: 20px;
      line-height: 28px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;
    margin-bottom: size(10px);

    @include viewport--md {
      align-items: flex-start;
      margin-top: 13px;
      margin-bottom: 9px;
    }

    @include viewport--sm {
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 6px;
    }
  }

  &__tags {
    max-width: 80%;
    margin: 0;
    padding: 0;

    list-style: none;

    @include viewport--md {
      margin-left: 5px;
    }

    @include viewport--sm {
      max-width: 100%;
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  &__tag {
    display: inline-block;

    @include viewport--sm {
      display: inline;
    }
  }

  &__tag-link {
    display: inline-block;
    margin-right: size(5px);

    font-weight: normal;
    font-size: size(16px);
    line-height: size(20px);
    color: $color-orange;

    @include viewport--md {
      margin-right: 12px;
    }

    @include viewport--sm {
      display: inline;
      margin-right: 9px;

      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 374px) {
      display: inline-block;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-silver;
      }
    }

    span {
      color: $color-default-black;
    }
  }

  &__date {
    font-weight: normal;
    font-size: size(16px);
    line-height: size(24px);

    color: $color-silver;
  }
}
