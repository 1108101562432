.btn {
  position: relative;

  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;

  text-align: center;
  color: inherit;
  text-decoration: none;

  background: $color-transparent;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;

  user-select: none;

  appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}
