.services {
  display: flex;
  flex-direction: column;

  padding-top: size(68px);
  padding-bottom: size(272px);

  @include viewport--md {
    padding-top: 70px;
    padding-bottom: 268px;
  }

  @include viewport--sm {
    padding-top: 48px;
    padding-bottom: 180px;
  }

  &__title {
    margin-left: size(-1px);

    @include viewport--md {
      margin-left: 2px;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: size(176px);

    @include viewport--md {
      margin-bottom: 0;
    }
  }

  &__head-text {
    width: 57%;
    margin: 0;
    margin-right: size(5px);
    margin-top: size(19px);

    font-weight: normal;
    font-size: size(24px);
    line-height: size(32px);

    @include viewport--md {
      display: none;
    }
  }
}
