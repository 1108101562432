.privacy {
  display: flex;
  flex-direction: column;

  padding-top: size(68px);
  padding-bottom: size(265px);

  @include viewport--md {
    padding-top: 70px;
    padding-bottom: 255px;
  }

  @include viewport--sm {
    padding-top: 48px;
    padding-bottom: 262px;
  }

  &__title {
    width: 62%;
    margin-bottom: size(65px);

    @include viewport--md {
      width: 100%;
      margin-bottom: 67px;
    }

    @include viewport--sm {
      margin-bottom: 52px;
    }

    @media (max-width: 374px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__content {
    margin: 0 0 size(162px);

    @include viewport--md {
      margin: 0 0 260px;
    }

    @include viewport--sm {
      margin: 0 0 262px;
    }

    &:last-child {
      margin: 0;

      @include viewport--md {
        margin: 0;
      }

      @include viewport--sm {
        margin: 0;
      }
    }

    h2 {
      max-width: 55%;
      margin: 0 0 size(70px);
      margin-left: size(-4.5px);

      font-weight: 600;
      font-size: size(50px);
      line-height: size(60px);

      @include viewport--md {
        max-width: 100%;
        margin-left: size(-5px);
      }

      @include viewport--sm {
        margin: 0 0 50px;
        margin-left: size(-3px);

        font-size: 32px;
        line-height: 36px;
      }

      @media (max-width: 374px) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    p {
      max-width: 83%;
      padding: 0;
      margin: 0 0 size(43px);

      font-weight: normal;
      font-size: size(32px);
      line-height: size(44px);

      @include viewport--md {
        max-width: 99%;
        margin: 0 0 31px;

        font-size: 24px;
        line-height: 32px;
      }

      @include viewport--sm {
        max-width: 100%;
        margin: 0 0 24px;

        font-size: 16px;
        line-height: 24px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  a {
    color: $color-silver;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }
  }
}
