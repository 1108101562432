.case {
  display: grid;
  grid-template-columns: minmax(70%, auto) size(310px);
  grid-template-rows: size(672px) 1fr;
  gap: size(30px) size(38px);
  grid-template-areas:
  "a b"
  "a .";

  margin-bottom: size(77px);

  &:last-child {
    margin-bottom: 0;
  }

  @include viewport--md {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(24px, auto);
    grid-auto-rows: minmax(24px, auto);
    grid-template-areas:
    "a"
    "b";
    gap: 24px;

    margin-bottom: 84px;
  }

  @include viewport--sm {
    grid-template-rows: minmax(22px, auto);
    grid-auto-rows: minmax(22px, auto);
    gap: 4px;

    margin-bottom: 61px;
  }

  &__link {
    grid-area: a;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {

        .case__title {
          color: $color-orange;
        }
      }
    }
  }

  img {
    width: 100%;
    height: size(672px);
    object-fit: cover;

    @include viewport--md {
      height: 464px;
    }

    @include viewport--sm {
      height: 228px;
    }
  }

  &__title {
    margin: 0;
    margin-top: size(30px);

    font-weight: 600;
    font-size: size(50px);
    line-height: size(60px);
    color: $color-default-black;

    @include viewport--md {
      width: 90%;
      margin-top: 32px;

      font-size: 32px;
      line-height: 36px;
    }

    @include viewport--sm {
      margin-top: 15px;

      font-size: 20px;
      line-height: 24px;
    }
  }

  &__tags {
    place-self: end start;
    display: flex;
    flex-wrap: wrap;

    margin: 0;
    margin-bottom: size(-8px);
    padding: 0;

    list-style: none;
    grid-area: b;

    @include viewport--md {
      place-self: auto;
      margin: 0;
      padding: 0;
    }

    @include viewport--sm {
    }
  }

  &__tag {
    margin-right: size(5px);

    &:last-child {
      margin-right: 0;

      span {
        display: none;
      }
    }
  }

  &__tag-link {
    display: block;
    font-weight: 500;
    font-size: size(24px);
    line-height: size(32px);

    color: $color-orange;

    @include viewport--md {
      font-size: 16px;
      line-height: 24px;
    }

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-silver;
      }
    }
  }

  &__divider {
    color: $color-default-black;
  }

  &--same {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(size(24px), auto);
    grid-auto-rows: minmax(size(24px), auto);
    gap: 0;
    grid-template-areas:
      "a"
      "b";

    margin-top: size(8px);
    //margin-bottom: size(84px);
    width: 48.5%;
    margin-right: 3%;
    margin-bottom: size(50px);

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:last-child {
      margin-bottom: size(50px);
    }

    @include viewport--md {
      width: 100%;
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include viewport--sm {
      margin-bottom: 52px;
    }

    img {
      height: size(440px);
      margin-bottom: size(36px);

      @include viewport--md {
        height: 464px;
        margin-bottom: 32px;
      }

      @include viewport--sm {
        height: 228px;
        //margin-bottom: 14px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    .case__tag-link {
      font-size: size(16px);
      line-height: size(24px);

      @include viewport--sm {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .case__title {
      font-size: size(32px);
      line-height: size(36px);

      @include viewport--md {
        margin-bottom: 23px;
      }

      @include viewport--sm {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
      }
    }

    .case__tags {
      grid-area: b;

      @include viewport--sm {
        margin-right: 0;
      }
    }
  }
}
