.project-slide {
  height: size(700px);

  @include viewport--md {
    height: 464px;
  }

  @include viewport--sm {
    height: 349px;
  }

  img {
    object-fit: cover;
    height: 100%;
  }
}
