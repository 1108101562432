.project-slide-thumbs {
  @include viewport--md {
    max-width: 70%;
  }

  @include viewport--sm {
    max-width: 100%;
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: size(20px);
    line-height: size(24px);
    color: $color-default-white;
    margin-bottom: size(11px);
  }

  &__tag {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    color: $color-silver;

    @include viewport--sm {
      max-width: 80%;
    }
  }
}
