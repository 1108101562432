.main-360magazine {
  padding-top: size(133px);
  padding-bottom: size(127px);

  @include viewport--md {
    padding-top: size(133px);
    padding-bottom: size(133px);
  }

  @include viewport--sm {
    padding-top: size(90px);
    padding-bottom: size(90px);
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include viewport--md {
      flex-direction: column;
    }
  }

  &__content {
    width: 71%;
    margin-right: size(42px);

    @include viewport--md {
      width: 100%;
      margin-bottom: 24px;
      margin-right: 0;
    }

    @include viewport--sm {
      margin-bottom: 56px;
    }
  }

  &__text {
    margin: 0;
    font-size: size(32px);
    line-height: size(44px);
    letter-spacing: -0.013em;

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__img {
    width: size(310px);
    height: size(244px);
    background-color: $color-whisper;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: size(30px);

    @include viewport--md {
      width: 100%;
      margin-bottom: 90px;
    }

    @include viewport--sm {
      margin-bottom: size(75px);
    }

    img {
      object-fit: contain;
      object-position: center;
    }
  }
}
