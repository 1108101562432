.blog {
  display: flex;
  flex-direction: column;

  padding-top: size(56px);
  padding-bottom: size(144px);

  @include viewport--md {
    padding-top: 58px;
    padding-bottom: 263px;
  }

  @include viewport--sm {
    padding-top: 48px;
    padding-bottom: 182px;
  }

  &__title-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: size(84px);
    margin-right: size(20px);

    @include viewport--md {
      margin-bottom: 85px;
    }

    @include viewport--sm {
      margin-bottom: 60px;
    }
  }

  &__tag {
    padding: 0;
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    align-self: flex-start;
    justify-content: flex-end;
    margin: size(30px) size(-5px) size(-5px);
    max-width: 30%;
    flex-shrink: 0;

    @include viewport--md {
      max-width: 35%;
    }

    @include viewport--sm {
      max-width: 55%;
      margin: 10px 0 -5px;
    }
  }

  &__tag-item {
    display: none;
    margin: 0 size(5px) size(10px);
    cursor: pointer;

    @include viewport--sm {
      margin: 0 0 10px;
    }

    &.show {
      display: flex;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        .blog__tag-text {
          color: $color-silver;
        }
      }
    }
  }

  &__tag-text {
    margin: 0;
    font-size: size(16px);
    line-height: size(20px);
    color: $color-orange;
    display: flex;

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
    }

    span {
      color: $color-default-black;
      position: relative;
      top: 1px;
      margin-right: 5px;
    }
  }

  &__tag-btn {
    position: relative;
    width: size(30px);
    display: flex;
    justify-content: center;

    span {
      position: absolute;
      display: flex;
      top: size(11px);

      width: size(12px);
      height: size(2px);
      background-color: $color-black;

      @include viewport--sm {
        top: size(13px);
      }

      &:first-child {
        transform: rotate(45deg);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}
