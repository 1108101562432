.container {
  width: 100%;
  max-width: size(1440px);
  margin: 0 auto;
  padding: 0 size(40px);
  position: relative;

  @include viewport--md {
    padding: 0 32px;
  }

  @include viewport--sm {
    padding: 0 16px;
  }

  &--no-padding {
    @include viewport--sm {
      padding: 0;
    }
  }

  &--404 {
    @include viewport--sm {
      padding: 0 15px;
    }
  }
}
