.modal-partner {
  &__head {
    position: relative;

    background-color: $color-white-smoke;
  }

  &__bg-image {
    width: 100%;
    height: size(360px);

    @include viewport--md {
      height: 372px;
    }

    @include viewport--sm {
      height: 216px;
    }

    img {
      width: 100%;
      max-height: 100%;
      object-fit: cover;

      @include viewport--md {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__logo {
    position: absolute;
    bottom: size(60px);
    left: size(40px);

    display: flex;
    align-items: flex-end;

    height: 50%;

    overflow: hidden;
    color: $color-default-white;

    @include viewport--md {
      bottom: 68px;

      height: 25%;
    }

    @include viewport--sm {
      left: 14px;
      bottom: 35px;

      height: 15%;
    }

    svg,
    img {
      width: auto;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    padding: size(43px) size(40px) size(83px);
    position: relative;

    @include viewport--md {
      padding: 43px 34px 83px;
    }

    @include viewport--sm {
      padding: 28px 16px 48px;
    }
  }

  &__meta {
    display: flex;
    flex-direction: column;
    margin-bottom: size(35px);

    font-weight: normal;
    font-size: size(16px);
    line-height: size(24px);
    color: $color-dim-gray;

    @include viewport--sm {
      margin-bottom: 24px;

      font-size: 12px;
      line-height: 16px;
    }
  }

  &__link {
    font-size: size(16px);
    line-height: size(24px);
    color: $color-dim-gray;
    text-decoration: underline;

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }
  }

  &__text {
    margin: 0;

    font-weight: normal;
    font-size: size(24px);
    line-height: size(32px);

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .modal__close-btn {
    color: $color-default-white;
  }

  &--main {
    .modal-partner__bg-image {
      height: size(660px);

      @include viewport--md {
        height: 696px;
      }

      @include viewport--sm {
        height: 344px;
      }

      img {
        height: 100%;
      }
    }

    .modal-partner__logo {
      bottom: size(50px);
      width: size(430px);
      height: size(123px);
      display: flex;
      align-items: center;

      @include viewport--md {
        width: 453px;
        height: 123px;
      }

      @include viewport--sm {
        width: 229px;
        height: 62px;
        bottom: 42px;
      }


      svg {
        @include viewport--sm {
          width: 165px;
          height: auto;
        }
      }

      img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
    }

    .modal-partner__content {
      background-color: $color-white-smoke;
      height: auto;
      padding: size(36px) size(40px) size(83px);

      @include viewport--md {
        height: auto;
        padding: 28px 60px 84px 34px;
      }

      @include viewport--sm {
        padding: 11px 21px 27px 15px;
      }
    }

    .modal-partner__text {
      overflow: hidden;

      @include viewport--md {
        display: inherit;
        overflow: inherit;
      }
    }
  }

  &--full {
    .tab-full {
      opacity: 1;
      display: flex;
    }

    .tab-original {
      opacity: 0;
      display: none;
    }
  }

  &--original {
    .tab-full {
      opacity: 0;
      display: none;
    }

    .tab-original {
      opacity: 1;
      display: flex;
    }
  }
}
