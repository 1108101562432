.search-form {
  position: relative;

  input {
    background-color: $color-white-smoke;
    padding: size(24px) size(75px) size(24px) size(24px);
    font-size: size(16px);
    line-height: size(24px);
    color: $color-nobel;
    width: 100%;
    border: none;
    min-height: size(76px);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(245, 244, 244, 1);


    @include viewport--md {
      width: 99%;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 16px;
      padding: 12px 45px 12px 11px;
      min-height: 56px;
    }
  }

  input::placeholder {
    font-size: size(16px);
    line-height: size(24px);
    color: $color-nobel;

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }
  }

  input:focus {
    outline: none;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input:focus {
      font-size: 16px;
    }
  }

  &__navigation-toggle.btn {
    position: absolute;
    right: size(20px);
    top: 50%;
    transform: translateY(-50%);
    width: size(43px);
    height: size(42px);
    color: $color-silver;
    // cursor: initial;

    @include viewport--md {
      right: 20px;
    }

    @include viewport--sm {
      right: 6px;
    }

    svg {
      color: $color-silver;
    }

    svg:first-child {
      display: flex;
    }

    svg:last-child {
      display: none;
    }
  }

  &.search-open {
    .search-form__navigation-toggle.btn {
      svg:first-child {
        display: none;
      }

      svg:last-child {
        display: flex;
      }
    }
  }

  &:hover {
    input {
      cursor: pointer;
    }

    svg {
      color: $color-default-black;
    }
  }
}
