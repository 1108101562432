.contact-form {
  max-width: size(664px);
  margin-left: auto;
  margin-right: auto;
  //padding-top: size(60px);
  padding-bottom: size(239px);

  @include viewport--md {
    width: 100%;
    max-width: unset;
  }

  @include viewport--sm {
    padding-top: 0;
    padding-bottom: 128px;
  }

  &--pb {
    padding-bottom: size(103px);

    @include viewport--md {
      padding-bottom: 132px;
    }

    @include viewport--md {
      padding-bottom: 31px;
    }
  }

  &__title {
    margin: size(40px) 0 size(77px);
    font-size: size(50px);
    font-weight: 400;
    line-height: size(60px);

    span {
      font-weight: 600;
    }

    @include viewport--sm {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 56px;
    }
  }

  &__input-block {
    margin-bottom: size(90px);

    @include viewport--sm {
      margin-bottom: 28px;
    }
  }

  &__btn,
  &__btn.link-with-line__link {
    position: relative;
    left: 0;
    top: 0;
    padding: 0;
    //display: inline-block;
    min-width: size(310px);
    //padding: 7px 0;
    margin-bottom: size(15px);

    font-family: $font-family--main;
    font-size: size(20px);
    font-style: normal;
    font-weight: 600;
    line-height: size(24px);
    text-align: left;
    color: $color-default-black;
    border: none;
    //border-bottom: 4px solid $color-orange;
    background-color: $color-transparent;
    cursor: pointer;

    @include viewport--sm {
      margin-bottom: 12px;
      min-width: unset;
    }

  }

  &__agree {
    width: size(310px);
    font-family: $font-family--main;
    font-size: size(16px);
    font-weight: 400;
    line-height: size(24px);
    color: $color-nobel;

    a {
      color: $color-nobel;
      text-decoration: underline;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-orange;
        }
      }
    }

    @include viewport--sm {
      max-width: 250px;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 30px;
    }

  }
}
