.scroll-up {
  //z-index: 9;
  z-index: 2147483647;
  bottom: 5%; //20%;

  width: 100%;
  max-width: size(1440px);
  padding: 0 size(79px);
  margin: 0 auto;

  display: flex;
  justify-content: flex-end;
  transition: position $transition;
  pointer-events: none;


  @include viewport--md {
    padding: 0 63px;
  }

  @include viewport--sm {
    padding: 0 31px;
  }

  &__btn {
    position: relative;
    width: size(76px);
    height: size(76px);

    border-radius: 50%;
    background-color: $color-white-smoke;
    pointer-events: all;

    @include viewport--md {
      width: 60px;
      height: 60px;
    }

    svg {
      position: absolute;
      width: 30px;
      height: 30px;

      left: calc(50% - 15px);
      top: calc(50% - 15px);


      transform: rotate(90deg);
      color: $color-default-black;
      transition: color $transition;

      @include viewport--md {
        width: 26px;
        height: 26px;

        left: calc(50% - 13px);
        top: calc(50% - 13px);
      }
    }
  }
}
