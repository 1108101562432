.main-blog {
  padding-top: size(133px);
  padding-bottom: size(133px);

  @include viewport--sm {
    padding-top: size(90px);
    padding-bottom: size(88px);
  }

  &__list {
    @include viewport--md {
      margin-bottom: 110px;
    }

    @include viewport--sm {
      margin-bottom: 67px;
    }

    .blog-list__item {
      @include viewport--md {
        margin-bottom: 118px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include viewport--sm {
        margin-bottom: 66px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
