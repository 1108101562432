.link-with-line {
  width: size(310px);
  position: absolute;
  right: size(40px);
  top: size(10px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include viewport--md {
    position: relative;
    bottom: 0;
    top: unset;
    right: 0;
    width: 329px;

    .intro--post & {
      width: 329px;
      bottom: -2px;
      margin-bottom: 85px;
      display: flex;
      justify-content: space-between;
    }

    .feedback & {
      position: absolute;
      right: 40px;
      top: 28px;
      bottom: auto;
      width: 209px;
    }
  }

  @include viewport--sm {
    position: relative;
    bottom: 0;
    top: unset;
    right: 0;
    width: 100%;

    .intro--post & {
      display: none;
    }

    .feedback & {
      display: none;
      position: absolute;
      right: auto;
      left: 16px;
      top: -60px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: size(4px);
    background-color: $color-orange;
    bottom: 0;
    left: 0;

    .intro--post &,
    .main-clients--about &,
    .feedback & {
      background-color: $color-whisper;

      @include viewport--md {
        bottom: 0;
        background-color: $color-whisper;
      }
    }

    .intro__back & {
      background-color: $color-whisper;
    }
  }

  &.page__back::after {
    @include viewport--sm {
      bottom: -7px;
    }
  }

  svg {
    opacity: 0;
    position: relative;
    bottom: size(8px);
    width: size(29px);
    height: size(29px);
    flex-shrink: 0;
    will-change: transform;
    transform: translate3d(0, 0, 0) rotate(-180deg);
    cursor: pointer;

    @include viewport--md {
      opacity: 1;
    }
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }

  &.intro__back svg {
    bottom: 0;
    transform: translate3d(0, 0, 0) rotate(0deg);
  }

  &.intro__back--post svg {
    bottom: 0;
    transform: rotate(0);
  }

  &.intro__back::after {
    background-color: $color-whisper;
  }

  &__link {
    width: 100%;
    font-weight: 600;
    font-size: size(20px);
    line-height: size(24px);
    color: $color-default-black;
    padding-bottom: size(12px);

    @include viewport--md {
      padding-bottom: 12px;
    }

    @include viewport--sm {
      .page__back & {
        padding-bottom: 6px;
      }
    }

    .intro__back & {
      padding-top: 6px;
      padding-bottom: 5px;
    }
  }

  &--hero {
    position: relative;
    top: unset;
    right: unset;
    width: inherit;
    align-items: flex-end;

    @include viewport--sm {
      width: 100%;
    }

    &::after {
      @include viewport--sm {
        height: 3px;
      }
    }

    .link-with-line__link {
      font-weight: 600;
      font-size: size(50px);
      line-height: size(60px);
      padding-bottom: size(20px);
      padding-right: size(45px);

      @include viewport--md {
        padding-right: size(60px);
      }

      @include viewport--sm {
        font-size: 32px;
        line-height: 36px;
        padding-bottom: 7px;
      }
    }

    svg {
      transform: rotate(0deg);
      opacity: 0;
      margin-bottom: size(6px);
      width: size(48px);
      height: size(48px);
      position: absolute;
      bottom: size(13px);
      right: 0;

      @include viewport--md {
        opacity: 1;
        right: 0;
        bottom: 15px;
      }

      @include viewport--sm {
        width: 29px;
        height: 30px;
        right: 2px;
        bottom: 5px;
      }

      .intro__back & {
        bottom: size(4px);
      }
    }

    .main-hero__accordion-btn > & {
      svg:first-of-type {
        display: block;
      }

      svg:last-of-type {
        display: none;
      }
    }

    .main-hero__accordion-btn.active > & {
      svg:last-of-type {
        display: block;

        @include viewport--md {
          right: -10px;
        }

        @include viewport--sm {
          width: 40px;
          height: 40px;
          right: -5px;
          bottom: -2px;
        }
      }

      svg:first-of-type {
        display: none;
      }
    }
  }

  &--header {
    right: unset;
    top: unset;
    bottom: 0;

    .link-with-line__link {
      padding-bottom: size(15px);
    }
  }
}
