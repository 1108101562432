.text-card {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content-title {
    font-weight: 500;
    font-size: size(24px);
    line-height: size(32px);
    margin: 0 0 size(27px);

    @include viewport--sm {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 19px;
    }
  }

  &__content-text {
    font-size: size(16px);
    line-height: size(24px);
    margin: 0;

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 22px;
      max-width: 97%;
    }
  }

  .link-with-line {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;
  }

  .link-with-line__link {
    margin-bottom: size(6px);

    @include viewport--sm {
      margin-bottom: 3px;
    }
  }

  .link-with-line svg {
    @include viewport--sm {
      opacity: 1;
    }
  }
}
