.logo-card {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: size(310px);
  gap: size(40px);

  @include viewport--md {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 208px;
    gap: 36px;
    margin-bottom: 94px;
  }

  @include viewport--sm {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 164px;
    gap: 16px 15px;
    margin-bottom: 64px;
  }

  &__item {
    display: flex;

    background-color: $color-white-smoke;
    padding: size(50px);

    @include viewport--sm {
      padding: 26px;
    }

    img {
      object-position: center;
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }
}
