.page-404 {
  display: flex;
  flex-direction: column;

  padding-top: size(68px);
  padding-bottom: size(322px);

  @include viewport--md {
    padding-top: 70px;
    padding-bottom: 272px;
  }

  @include viewport--sm {
    padding-top: 48px;
    padding-bottom: 106px;
  }

  &__title {
    margin-bottom: size(68px);

    @include viewport--md {
      margin-bottom: 71px;
    }

    @include viewport--sm {
      margin-bottom: 52px;
    }
  }

  &__text {
    width: 74%;
    padding: 0;
    margin: 0;

    font-weight: normal;
    font-size: size(50px);
    line-height: size(60px);

    &:last-child {
      margin: 0;
    }

    @include viewport--md {
      width: 100%;
    }

    @include viewport--sm {
      font-size: 32px;
      line-height: 36px;
    }

    @media (max-width: 374px) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  &__link {
    font-weight: bold;
    color: $color-default-black;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }
  }
}
