.review {
  display: flex;
  flex-direction: column;

  background-color: $color-white-smoke;

  &__link {
    display: flex;
    flex-direction: column;

    padding: size(40px) size(35px) 0;

    @include viewport--md {
      padding: 40px;
      padding-bottom: 0;
    }

    @include viewport--sm {
      padding: 13px;
      padding-bottom: 0;
    }
  }

  &__about {
    display: flex;

    margin-bottom: size(32px);


    @include viewport--md {
      margin-bottom: 30px;
    }

    @include viewport--sm {
      flex-wrap: wrap;

      margin-bottom: 22px;
      max-height: none;
    }
  }

  &__avatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: size(76px);
    height: size(76px);

    border-radius: 50%;
    overflow: hidden;

    @include viewport--md {
      width: 80px;
      height: 80px;
    }

    @include viewport--sm {
      width: 90px;
      height: 90px;
    }
  }

  &__avatar-image {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  &__initials {
    position: absolute;

    width: 100%;
    height: 100%;
    padding-top: calc(50% - 21%);

    font-weight: 600;
    font-size: size(28px);
    line-height: size(28px);
    color: $color-whisper;
    background-color: $color-nobel;
    user-select: none;
    text-align: center;
  }

  &__personal {
    display: flex;
    flex-direction: column;

    max-width: 40%;

    margin-top: size(15px);
    margin-left: size(20px);

    @include viewport--sm {
      order: 3;
      flex-basis: 100%;

      max-width: 100%;

      margin: 0;
      margin-top: 30px;
    }
  }

  &__name {
    margin-bottom: size(6px);

    font-weight: 600;
    font-size: size(20px);
    line-height: size(24px);
  }

  &__position,
  &__company-name {
    font-weight: normal;
    font-size: size(16px);
    line-height: size(24px);

    color: $color-dim-gray;

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__company-name {
    display: none;

    @include viewport--sm {
      display: block;
    }
  }

  &__company-logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-left: auto;
    flex-basis: 40%;

    @include viewport--sm {
      flex-basis: 50%;
    }

    img {
      max-height: 100%;

      object-fit: contain;
    }
  }

  &__text {
    margin: 0;
    margin-bottom: size(20px);

    font-weight: normal;
    font-size: size(24px);
    line-height: size(32px);

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;

    margin-top: auto;
    padding: 0 size(35px) size(40px);

    opacity: 0;

    @include viewport--md {
      opacity: 1;
      padding: 0 40px 36px;
    }

    @include viewport--sm {
      padding: 0 13px 25px;
    }
  }

  &__btn {
    font-weight: 500;
    font-size: size(16px);
    line-height: size(20px);

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
    }

    &--full {
      color: $color-orange;
    }

    &--original {
      color: $color-nobel;
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:focus-within {
      .review__btns {
        opacity: 1;
      }
    }
  }
}
