.custom-select {
  position: relative;

  //width: 200px;
  //padding: 30px 0;
  padding: 0;

  &.modal-lang__input-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }

  &__label {
    position: absolute;
    //top: 50%;
    left: 10px;
    color: $color-default-white;

    //transform: translateY(-50%);

    transition: transform $transition, opacity $transition;

    pointer-events: none;

    .modal-lang__input-wrap & {
      position: relative;
      left: 0;
      top: 0;
      font-size: size(20px);
      font-weight: 600;
      line-height: size(24px);
      color: $color-default-white;
      transform: none;

      @include viewport--sm {
        font-size: 18px;
      }
    }
  }

  &__placeholder {
    position: absolute;
    //top: 50%;
    left: 10px;

    //transform: translateY(-50%);

    transition: transform $transition, opacity $transition;

    pointer-events: none;

    .modal-lang__input-wrap & {
      position: relative;
      transform: none;
      top:auto;
    }
  }

  select {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;

    clip: rect(0 0 0 0);
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    color: $color-default-white;

    background-color: $color-transparent;
    border: 2px solid rgba($color-default-black, 0.2);
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    transition: border $transition;

    &:hover {
      border: 2px solid rgba($color-default-black, 0.5);
    }

    &:focus {
      border: 2px solid rgba($color-default-black, 0.8);
    }

    .modal-lang & {
      width: size(87px);
      height: auto;
      right: 0;
      margin-left: 4px;
      padding: 0;
      font-size: size(20px);
      font-weight: 600;
      line-height: size(24px);
      color: $color-default-white;
      border-bottom: none;
      border: none;
      border-radius: 0;
    }

    .modal-lang .modal-lang__input-wrap--region & {
      width: size(142px);

      @include viewport--sm {
        font-size: 18px;
        width: 130px;
      }
    }

    .modal-lang .modal-lang__input-wrap--lang & {
      width: size(122px);

      @include viewport--sm {
        font-size: 18px;
        width: 100px;
      }
    }
  }

  &__list {
    position: absolute;
    bottom: 32px;
    z-index: 1;

    width: 100%;
    max-height: 100px;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    list-style: none;

    background-color: $color-default-white;
    border: 2px solid $color-default-black;
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;

    transition: visibility $transition, opacity $transition;

    .modal-lang & {
      position: relative;
      bottom: auto;
      width: auto;
      max-height: 100%;
      transform: none;
      margin-left: auto;
      background-color: $color-default-black;
      border: none;
    }

    .modal-lang__input-wrap--region & {
      min-width: size(111px);
      margin-right: size(36px);

      @include viewport--sm {
        margin-right: 25px;
      }
    }

    .modal-lang__input-wrap--lang & {
      min-width: size(87px);
      margin-right: size(57px);

      @include viewport--sm {
        margin-right: 25px;
      }
    }
  }

  &__item {
    padding: 5px;

    outline: none;
    cursor: pointer;

    transition: background-color $transition;

    /*&[aria-selected="true"] {
      background-color: pink;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          background-color: red;
        }
      }
    }*/

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        //background-color: gray;
      }
    }

    .modal-lang & {
      padding: 0;
      margin: 0;
      margin-bottom: size(3px);
      font-size: size(20px);
      line-height: size(24px);
      font-weight: 600;
      color: $color-default-white;

      &[aria-selected="true"] {
        background-color: $color-default-black;
        //color: $color-orange;
        display: none;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-orange;
          background-color: $color-transparent;
        }
      }

      @include viewport--sm {
        font-size: 18px;
      }
    }

    &.hidden {
      display: none;
    }
  }

  &__error {
    position: absolute;
    bottom: 0;
    left: 0;

    color: red;

    opacity: 0;

    transition: opacity 0.1s ease;
  }

  &__text {
    flex-shrink: 0;
    width: 80%;
    overflow: hidden;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    opacity: 0;

    transition: opacity $transition 0.1s;

    br {
      display: none;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 24px;
    height: 10px;
    //margin-left: auto;

    background-color: $color-default-black;

    transition: transform $transition;

    //clip-path: polygon(100% 0, 100% 20%, 50% 100%, 0 20%, 0 0, 50% 80%);

    .modal-lang & {
      //position: absolute;
      width: size(14px);
      height: size(14px);
      display: flex;
      align-items: flex-end;

      svg {

        width: size(14px);
        height: size(14px);


        /*@include viewport--sm {
          right: -2px;
          top: -3px;
        }*/
      }
    }

  }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: rotate(180deg);


    }

    .custom-select__label {
      opacity: 0;
    }
  }

  &.is-open.custom-select--slide-up {
    .custom-select__label {
      //transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      border: 2px solid red;

      &:focus,
      &:hover {
        border: 2px solid rgba(red, 0.5);
      }
    }
  }

  /*&.is-valid {
    .custom-select__button {
      border: 2px solid green;

      &:focus,
      &:hover {
        border: 2px solid rgba(green, 0.5);
      }
    }
  }*/

  &--placeholder {
    .custom-select__label {
      //transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &--slide-up {
    &.not-empty {
      .custom-select__label {
        //transform: translateY(calc(-50% - 40px));
        opacity: 1;
      }

      .custom-select__text {
        opacity: 1;
      }
    }
  }
}
