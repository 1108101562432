.slider {
  position: relative;

  &__btn {
    width: size(76px);
    height: size(76px);
    top: unset;
    bottom: size(20px);

    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-default-white;
    background-color: $color-default-black;

    &::after {
      display: none;
    }

    &.swiper-button-prev {
      left: size(20px);

      svg {
        transform: rotate(90deg);
      }

      @include viewport--md {
        left: 0;
        bottom: 0;
      }

      @include viewport--sm {
        bottom: 76px;
        border-bottom: 2px solid $color-default-white;
        border-top: 2px solid $color-default-white;
      }
    }

    &.swiper-button-next {
      left: size(176px);

      svg {
        transform: rotate(-90deg);
      }

      @include viewport--md {
        width: 78px;
        left: 156px;
        bottom: 0;
        border-right: 2px solid $color-default-white;
      }

      @include viewport--sm {
        bottom: 76px;
        right: 0;
        left: unset;
        border-right: none;
        border-bottom: 2px solid $color-default-white;
        border-top: 2px solid $color-default-white;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover svg,
      &:focus svg {
        opacity: 0.6;
      }
    }

    .page__slider & {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover svg,
        &:focus svg {
          opacity: 1;
          color: $color-default-black;
        }

        &:hover,
        &:focus {
          background-color: $color-gainsboro;
        }
      }
    }
  }

  &__thumbs {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 1.47%; //20px;
    width: 22.79%;

    background-color: $color-default-black;

    img {
      //width: 19.85%; //270px;
      //height: 184px;
    }

    @include viewport--md {
      width: 100%;
      top: 100%;
      left: 0;
      height: 216px; //auto;
      border-top: 2px solid $color-default-white;
    }

    @include viewport--sm {
      display: none;
    }
  }

  &__pagination {
    width: size(80px);
    height: size(76px);
    top: unset;
    bottom: size(20px);
    left: size(96px);

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: $font-family--main;
    font-size: size(32px);
    font-weight: 400;
    line-height: size(36px);
    color: $color-default-white;
    background-color: $color-default-black;
    border-left: 2px solid $color-default-white;
    border-right: 2px solid $color-default-white;

    @include viewport--md {
      left: 76px;
      bottom: 0;
    }

    @include viewport--sm {
      left: 76px;
      right: 78px;
      bottom: 76px;
      width: unset;
      border-right: 2px solid $color-default-white;
      border-bottom: 2px solid $color-default-white;
      border-top: 2px solid $color-default-white;
    }
  }

  &__img-wrap {
    width: 100%;
    height: 48.61vw; //auto;
    background-color: $color-default-black;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }

    .video {
      width: 100%;
      height: 100%;


      video {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    @include viewport--xxl {
      height: 700px;
    }

    @include viewport--md {
      position: relative;
      height: 700px;

      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

      .video {
        width: 100%;
        height: 100%;

        video {
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }

    @include viewport--sm {
      height: 346px;

      video {
        max-width: unset;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__thumbs-wrap {
    max-width: 100%;
    height: 14.2vw;
    overflow: hidden;
    cursor: pointer;

    img {
      max-width: 100%;
      height: 184px; //100%;
      object-fit: cover;
      object-position: center center;
    }

    @include viewport--xxl {
      height: 184px;
    }

    @include viewport--md {
      height: 184px;

      img,
      video {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  &__main {
    width: 77.21%;
  }

  &__main.swiper-container {
    width: 77.21%;
    margin-left: 0;

    @include viewport--md {
      width: 100%;
      height: 776px;
      background-color: $color-default-black;
    }

    @include viewport--sm {
      height: 498px;
    }
  }

  /*&__slide {
    position: relative;
    width: 100%;
    //height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-default-black;

    /*img {
      object-fit: contain;
      object-position: center center;
    }*/
  /*}*/

  &__text {
    position: absolute;
    height: size(76px);
    bottom: size(20px);
    max-width: size(475px);
    left: size(252px);
    padding-left: size(25px);
    padding-right: size(20px);
    display: inline-flex;
    align-items: center;

    font-family: $font-family--main;
    font-size: size(16px);
    font-style: normal;
    font-weight: 400;
    line-height: size(24px);

    color: $color-default-white;
    background-color: $color-default-black;
    border-left: 2px solid $color-default-white;


    @include viewport--md {
      width: calc(100vw - 296px);
      padding-right: 30px;
      max-width: unset;
      left: 232px;
      bottom: 0;
      border-left: none;
    }

    @include viewport--sm {
      width: 100%;
      bottom: 0;
      left: 0;
      white-space: unset;
      //border-top: 2px solid $color-default-white;
    }

    span {
      display: inline;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include viewport--md {
        white-space: unset;
      }
    }
  }

  &--content {
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      /* opacity: .35; */
      opacity: 1;
      cursor: auto;
      pointer-events: none;

      svg {
        opacity: 0.4;
      }
    }
  }


  &--mission {
    .swiper-pagination {
      bottom: 0;
      top: unset;
    }

    .swiper-pagination-progressbar {
      background: $color-default-black;
    }

    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      background: $color-orange;
    }

    .swiper-container {
      height: 100%;
    }
  }

  &--project {
    .main-project__slider-thumbs {
      .swiper-pagination {
        display: none;

        @include viewport--sm {
          display: block;
          left: 15px;
          top: 3px;
          bottom: unset;
          z-index: 10;
          text-align: left;
        }
      }

      .swiper-pagination-bullet {
        background: $color-default-white;
        opacity: 1;
        border-radius: 0;
        width: 4px;
        height: 4px;
        margin: 0 2px;
      }

      .swiper-pagination-bullet-active {
        background: $color-orange;
      }
    }

    .slider__progress.active span {
      transition: width 9.94s linear;
    }
  }

  &--feedback {
    .slider__prev {
      width: size(76px);
      height: size(76px);
      left: unset;
      right: unset;
      bottom: size(-2px);

      svg {
        width: size(30px);
        height: size(30px);
      }

      &::after {
        content: none;
      }

      &:hover {
        background: $color-gainsboro;
      }
    }

    .slider__next {
      width: size(76px);
      height: size(76px);
      left: unset;
      right: unset;
      bottom: size(-2px);

      svg {
        transform: rotate(180deg);
        width: size(30px);
        height: size(30px);
      }

      &::after {
        content: none;
      }

      &:hover {
        background: $color-gainsboro;
      }
    }

    .slider__pagination {
      position: relative;
      left: unset;
      right: unset;
      top: unset;
      bottom: unset;
      width: size(75px);
      height: size(77px);
      background-color: unset;
      border: none;
      color: $color-default-black;
    }

    .slider__navigation {
      position: absolute;
      right: 0;
      top: size(240px);
      display: flex;
      justify-content: space-between;
      width: size(310px);

      @include viewport--md {
        justify-content: flex-start;
        top: unset;
        bottom: 53px;
        right: unset;
        left: 0;
        width: auto;
      }

      @include viewport--sm {
        justify-content: space-between;
        width: 100%;
        bottom: 66px;
      }
    }
  }

  &__progress {
    height: 4px;
    width: 100%;
    background-color: $color-default-black;
    position: absolute;
    bottom: 0;
    z-index: 2;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $color-orange;
    }

    &.active {
      span {
        width: 100%;
        transition: width 4.94s linear;
        transform: translateZ(0);
      }
    }
  }
}
