.main-people {
  padding-top: size(262px);
  padding-bottom: size(128px);

  @include viewport--md {
    padding-top: size(228px);
  }

  @include viewport--sm {
    padding-top: size(158px);
    padding-bottom: size(86px);
  }

  &__heading {
    display: flex;
    margin-bottom: size(51px);

    @include viewport--md {
      margin-bottom: 52px;
      flex-direction: column;
    }

    @include viewport--sm {
      margin-bottom: 33px;
    }
  }

  &__heading-text {
    margin-left: size(38px);
    width: 48.5%;
    flex-shrink: 0;
    padding-top: size(12px);

    @include viewport--md {
      width: 100%;
      margin-left: 0;
      margin-top: 70px;
    }

    @include viewport--sm {
      margin-top: 52px;
    }

    p {
      margin: 0;
      font-size: size(24px);
      line-height: size(32px);

      @include viewport--sm {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  &__list {
    width: 48.5%;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    min-height: size(428px);

    @include viewport--md {
      width: 100%;
      min-height: auto;
    }
  }

  &__part-name {
    font-size: size(32px);
    font-style: normal;
    font-weight: 400;
    line-height: size(44px);

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__accordion-btn {
    position: relative;
    width: 100%;
    padding: size(23px) 0;
    display: flex;

    background-color: $color-transparent;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      width: size(29px);
      height: size(29px);
      margin-left: auto;
      margin-top: 8px;
      margin-right: 2px;
      color: $color-default-black;
      flex-shrink: 0;
      opacity: 0;
      transform: rotate(-90deg);

      @include viewport--md {
        opacity: 1;
        margin-right: 7px;
      }

      @include viewport--sm {
        margin-top: -5px;
        margin-right: -3px;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }

      &:hover svg,
      &:focus svg {
        opacity: 1;
        color: $color-default-black;
      }
    }

    @include viewport--sm {
      padding: 18.5px 0 16px;
    }

    &.active {
      color: $color-orange;

      svg {
        opacity: 1;
        transform: rotate(90deg);
      }
    }
  }

  &__item {
    border-bottom: size(2px) solid $color-whisper;
  }

  &__content {

    padding: 0;
    display: block;
    max-height: 0;
    overflow: hidden;
    pointer-events: auto;
  }

  &__content-wrap {
    @include viewport--md {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__link-list {
    padding: 0;
    list-style: none;
    margin-bottom: size(35px);

    @include viewport--md {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -17.5px 30px;
    }

    @include viewport--sm {
      margin: 5px 0 0;
    }
  }

  &__link-item {
    padding-bottom: size(12px);

    @include viewport--md {
      padding-bottom: 30px;
      margin: 0 17.5px;
      width: calc(100% / 2 - 35px);
      display: grid;
      grid-auto-rows: 1fr 216px;
      gap: 12px;
    }

    @include viewport--sm {
      margin: 0;
      width: 100%;
      gap: 3px;
      grid-auto-rows: 1fr 224px;
    }

    &:last-child {
      @include viewport--sm {
        margin-bottom: 40px;
      }
    }

    &.show {
      .main-people__link-pic {
        opacity: 1;

        @include viewport--md {
          z-index: 1;
          opacity: 1;
        }
      }
    }
  }

  &__link {
    font-size: size(16px);
    font-weight: 500;
    line-height: size(20px);
    color: $color-default-black;
    display: block;

    @include viewport--md {
      margin-top: auto;
    }

    @include viewport--sm {
      margin-bottom: 8px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }
  }

  &__link-pic {
    opacity: 0;
    position: absolute;
    left: 106%;
    top: size(32px);
    width: size(660px);
    height: size(428px);
    z-index: 5;
    pointer-events: none;

    @include viewport--md {
      height: size(216px);
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 1;
      margin-top: auto;
    }

    @include viewport--sm {
      width: 100%;
      height: size(224px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__pic {
    position: absolute;
    top: size(32px);
    left: 106%;
    width: 100%;
    height: size(428px);
    opacity: 0;

    img {
      height: 100%;
      object-fit: cover;
    }

    &.show {
      opacity: 1;
    }

    @include viewport--md {
      display: none;
      position: relative;
      top: 0;
      left: 0;
      opacity: 1;
      height: 464px;
      margin-top: 4px;
      margin-bottom: 41px;
    }

    @include viewport--sm {
      margin-top: 0;
      margin-bottom: 30px;
      height: 344px;
    }
  }
}
