.modal-review {
  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: size(40px);
    padding-bottom: size(32px);

    background-color: $color-white-smoke;
  }

  &__avatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: size(36px);
    width: size(140px);
    height: size(140px);

    border-radius: 50%;

    overflow: hidden;

    @include viewport--md {
      width: 144px;
      height: 144px;
    }

    @include viewport--sm {
      width: 100px;
      height: 100px;
    }
  }

  &__initials {
    position: absolute;

    width: 100%;
    height: 100%;
    padding-top: calc(50% - 25px);


    font-weight: 600;
    font-size: size(50px);
    line-height: size(50px);
    color: $color-whisper;
    background-color: $color-nobel;
    user-select: none;
    text-align: center;

    @include viewport--sm {
      padding-top: calc(50% - 16px);

      font-size: 32px;
      line-height: 32px;
    }
  }

  &__avatar-image {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  &__title {
    font-weight: 600;
    font-size: size(32px);
    line-height: size(36px);

    margin-bottom: size(5px);

    @media (max-width: 374px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__about {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      display: block;

      font-weight: normal;
      font-size: size(16px);
      line-height: size(24px);

      color: $color-dim-gray;
    }
  }

  &__main {
    padding: size(40px);
    padding-top: size(93px);
    background-color: $color-default-white;

    @include viewport--md {
      padding: size(35px);
      padding-top: size(90px);
    }

    @include viewport--sm {
      padding: size(15px);
      padding-top: size(46px);
    }
  }

  &__buttons {
    display: flex;
    margin-bottom: size(36px);
  }

  &__button {
    flex-basis: 50%;

    padding-bottom: size(10px);

    font-weight: 600;
    font-size: size(20px);
    line-height: size(24px);

    border-bottom: size(4px) solid $color-whisper;

    @media (max-width: 374px) {
      font-size: 18px;
      line-height: 22px;
    }

    &.active {
      color: $color-orange;

      border-bottom: size(4px) solid $color-orange;
      pointer-events: none;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-orange;
        }
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    p {
      margin-top: 0;

      font-weight: normal;
      font-size: size(24px);
      line-height: size(32px);

      @include viewport--sm {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }



}
