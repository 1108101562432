.services-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(size(332px), auto);
  grid-auto-rows: minmax(size(332px), auto);
  gap: size(40px);

  margin: 0;
  padding: 0;

  list-style: none;

  @include viewport--md {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(188px, auto);
    grid-auto-rows: minmax(188px, auto);
    gap: 36px;
  }

  @include viewport--sm {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(144px, auto);
    grid-auto-rows: minmax(144px, auto);
    gap: 16px;
  }

  &__item {
    display: flex;
    overflow: hidden;
  }

  &__link {
    position: relative;
    padding: size(30px) size(38px);

    background-color: $color-whisper;
    cursor: pointer;

    @include viewport--md {
      width: 100%;
      padding: 32px 35px;
    }

    @include viewport--sm {
      padding: 14px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        @media (min-width: 1024px) {
          background-color: transparent;

          .services-list__title {
            color: $color-default-white;
          }

          .services-list__bg {
            opacity: 1;
          }
        }
      }
    }
  }

  &__title {
    position: relative;
    z-index: 3;

    margin: 0;

    font-weight: 600;
    font-size: size(32px);
    line-height: size(40px);

    @include viewport--md {
      font-size: 32px;
      line-height: 36px;
    }

    @include viewport--sm {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__title-lg {
    @include viewport--md {
      display: none;
    }
  }

  &__bg {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;

    @include viewport--md {
      display: none;
    }

    img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }

    svg {
      position: absolute;
      z-index: 4;
      left: size(38px);
      bottom: size(42px);

      color: #ff9600;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 5;
      left: size(38px);
      bottom: size(42px);

      width: calc(100% - 38px * 2);
      height: size(170px);

      border: size(4px) solid $color-default-white;
    }
  }
}
