.main-clients {
  padding-top: size(127px);
  padding-bottom: size(133px);

  @include viewport--md {
    padding-top: size(133px);
    padding-bottom: size(133px);
  }

  @include viewport--sm {
    padding-top: size(80px);
    padding-bottom: size(91px);
  }
}
