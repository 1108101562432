.clients-content {
  display: flex;
  flex-direction: column;
  min-height: 482px;

  &__wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__group {
    width: 50%;
    padding-right: 2%;
    margin-bottom: size(166px);

    @include viewport--sm {
      width: 100%;
      padding-right: 0;
      margin-bottom: 176px;
    }

    &:nth-child(odd) {
      float: left;
    }

    &:nth-child(even) {
      float: right;

    //   @media (min-width: 1024px) and (max-width: 1440px) {
    //     width: 47%;
    //   }
    }

    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  &__group-wrapper {
    display: flex;
    justify-content: space-between;

    .clients-content__group {
      float: none;

      margin-bottom: 0;
    }

    @include viewport--sm {
      display: block;
    }
  }

  &__column {
    width: 48%;
    margin-bottom: size(166px);
  }


  &__clear {
    width: 100%;
    clear: both;
  }

  &__title {
    margin-bottom: size(70px);
    font-weight: 600;
    font-size: size(50px);
    line-height: size(60px);

    @include viewport--sm {
      margin-bottom: 50px;

      font-size: 32px;
      line-height: 36px;
    }

    @media (max-width: 374px) {
      font-size: 24px;
      line-height: 28px;
    }

    &--single {
      width: 60%;

      @include viewport--sm {
        width: 100%;
      }
    }

  }

  &__list {
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;

    list-style: none;

    @include viewport--sm {
      max-width: 75%;
    }
  }

  &__name {
    font-weight: normal;
    font-size: size(24px);
    line-height: size(32px);


    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
    }

  }
}
