.intro {
  position: relative;
  color: $color-default-black;
  padding-top: size(67px);
  padding-bottom: size(20px);

  @include viewport--md {
    padding-bottom: 30px;
  }

  @include viewport--sm {
    padding-top: 40px;
    padding-bottom: 14px;
  }

  &--post {
    @include viewport--md {
      padding-top: 54px;
      padding-bottom: 14px;
    }

    @include viewport--sm {
      padding-top: 40px;
      padding-bottom: 14px;
    }
  }

  &--about {
    padding-top: size(104px);

    @include viewport--md {
      padding-top: 66px;
      padding-bottom: 35px;
    }

    @include viewport--sm {
      padding-top: 52px;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include viewport--md {
      flex-direction: column-reverse;
    }
  }

  &__img-wrap {
    position: relative;
    //margin: 0 0 size(40px);
    height: 700px;
    max-width: 100%;
    margin-bottom: size(83px);

    img {
      height: 100%;
      width: 100%;
      max-width: 100%;
      margin: 0;
      object-fit: cover;
      object-position: center center;
    }

    @include viewport--sm {
      height: 344px;
      margin-bottom: 55px;

      .intro--post & {
        margin-bottom: 60px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }


  &__title {
    width: calc(100% - 340px);
    max-width: size(897px);
    margin-right: size(30px);
    margin-left: size(-4px);

    @include viewport--md {
      width: 100%;
    }


    .intro--about & {
      @include viewport--md {
        margin-bottom: 57px;
      }
    }

  }

  &__back {
    position: relative;
    width: size(310px);
    padding: size(11px) 0 size(10px) 0;
    //margin-top: size(11px);
    right: 0;
    top: size(3px);

    @include viewport--md {
      display: none;
    }

    &--post {
      @include viewport--md {
        display: block;
      }

      @include viewport--sm {
        display: none;
      }
    }
  }

  &__promo {
    max-width: size(898px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: size(214px);

    font-family: $font-family--main;
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);

    h1 {
      line-height: size(48px);
    }

    @include viewport--md {
      .intro--service & {
        margin-bottom: 200px;
      }
    }

    @include viewport--sm {
      margin-bottom: 136px;
      font-size: 20px;
      line-height: 28px;

      .intro--service & {
        margin-bottom: 138px;
      }
    }

    .intro--post & {
      margin-bottom: size(210px);

      @include viewport--md {
        margin-bottom: 217px;
      }

      @include viewport--sm {
        margin-bottom: 143px;
      }
    }

    .intro--about & {
      max-width: size(890px);
      margin-bottom: size(52px);

      @include viewport--md {
        margin-bottom: 80px;
      }

      @include viewport--sm {
        margin-bottom: 54px;
      }
    }
  }

  &__img-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: $color-default-white;

    svg {
      width: size(700px);
      height: size(183px);
    }

    @include viewport--md {
      svg {
        width: 466px;
        height: 121px;
      }
    }

    @include viewport--sm {
      svg {
        width: 223px;
        height: 57px;
      }
    }
  }
}
