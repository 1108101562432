.accordion {
  list-style: none;
  margin: 0;

  &__content {
    display: block;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    opacity: 1;
  }
}
