.main-feedback {
  padding-top: size(133px);
  padding-bottom: size(129px);

  @include viewport--md {
    padding-bottom: size(135px);
  }

  @include viewport--sm {
    padding-top: size(91px);
    padding-bottom: size(90px);
  }

  &__slider {
    @include viewport--md {
      margin-bottom: 84px;
    }

    @include viewport--sm {
      margin-bottom: 60px;
    }
  }
}
