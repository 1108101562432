@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Graphik LC";

  font-display: swap;
  src:
    url("../../build/fonts/graphik-regular-cy.woff2") format("woff2"),
    url("../../build/fonts/graphik-regular-cy.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Graphik LC";

  font-display: swap;
  src:
    url("../../build/fonts/graphik-medium-cy.woff2") format("woff2"),
    url("../../build/fonts/graphik-medium-cy.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Graphik LC";

  font-display: swap;
  src:
    url("../../build/fonts/graphik-semibold-cy.woff2") format("woff2"),
    url("../../build/fonts/graphik-semibold-cy.woff") format("woff");
}
