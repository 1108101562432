.page {
  font-family: $font-family--main;
  color: $color-default-black;
  padding-bottom: size(160px);

  @include viewport--md {
    padding-bottom: 122px;
  }

  @include viewport--sm {
    padding-bottom: 54px;
  }

  &__link {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }
  }

  &__title {
    width: 50%;
    margin: 0;
    padding: size(20px) 0 size(50px);
    font-size: size(50px);
    font-weight: 600;
    line-height: size(60px);

    @include viewport--md {
      width: 100%;
    }

    @include viewport--sm {
      font-size: 32px;
      line-height: 36px;
      padding-bottom: 31px;
    }

    &--md {
      @include viewport--md {
        max-width: 545px;
      }
    }

    &--container {
      width: 100%;
      max-width: size(1440px);
      margin: 0 auto size(54px);
      padding: size(20px) size(40px) 0;
      position: relative;

      @include viewport--md {
        padding: 0 32px;
      }

      @include viewport--sm {
        padding: 0 16px;
      }
    }

    &--mb {
      @include viewport--sm {
        margin-bottom: 30px;
      }
    }
  }

  &__full-img-wrap {
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    max-height: size(728px);
    padding-top: size(28px);
    margin-bottom: size(67px);
    overflow: hidden;

    &--video {
      margin-bottom: size(71px);
    }

    img {
      object-fit: contain;
      object-position: left top;
    }

    video {
      //position: absolute;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: left top;
    }

    @include viewport--md {
      height: 516px; //488px;
      margin-top: 0;

      img {
        max-width: unset;
        max-height: 100%;
        object-fit: contain;
        object-position: left center;
      }
    }

    @include viewport--sm {
      padding-top: 25px;
      height: 369px;
      margin-bottom: 45px;
    }

    &--sm {
      @include viewport--md {
        height: 492px;
        margin-bottom: 71px;
      }

      @include viewport--sm {
        height: 252px; //228px;
        margin-bottom: 45px;
        padding-top: 24px;
      }
    }

    &--video {
      margin-top: size(30px);
      margin-bottom: size(71px);
      height: size(700px);

      /*video,
      img {
        bottom: 0;
        position: absolute;
        object-fit: cover;
        object-position: left bottom;
        height: 100%;
        width: 100%;
        background-color: $color-default-black;

        source {
          position: absolute;
          bottom: 0;
          left: 0;
          object-fit: contain;
        }
      }*/

      @include viewport--md {
        width: 100%;
        height: 518px;
        max-height: unset;
        margin-bottom: 71px;
        margin-top: 0;

        video,
        img {
          position: relative;
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%;
          background-color: $color-default-black;
        }
      }

      @include viewport--sm {
        height: 370px;
        margin-bottom: 44px;
      }
    }
  }

  &__inner-subtitle {
    margin: 0;
    padding: size(20px) 0 size(24px);
    font-size: size(32px);
    font-weight: 600;
    line-height: size(36px);

    @include viewport--md {
      padding-bottom: 20px;

      &--pb {
        margin-top: -7px;
        padding-bottom: 0;
      }
    }

    @include viewport--sm {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 20px;

      &:only-child {
        padding-bottom: 0;
      }
    }
  }

  &__inner-subtitle + .page__content {
    padding-top: 0;
  }

  &__tags {
    position: relative;
    padding-top: size(20px);
    padding-bottom: size(60px);
    display: flex;
    flex-wrap: wrap;

    &::before {
      content: "";
      position: absolute;

      left: 0;
      right: 0;
      height: 2px;
      background-color: $color-whisper;
    }

    &::before {
      top: size(20px);

      @include viewport--md {
        top: 3px;
      }
    }

    @include viewport--md {
      padding-top: 33px;
      flex-wrap: wrap;
      border-bottom: none;
    }

    @include viewport--sm {
      padding-top: 4px;
      padding-bottom: 40px;
    }

    &--about {
      margin-bottom: 90px;

      @include viewport--md {
        padding-top: 0;
        margin-bottom: 172px;

        &::before {
          top: 0;
        }
      }

      @include viewport--sm {
        margin-bottom: 79px;
      }
    }
  }

  &__tags-block {
    width: 16.4%;
    min-height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family--main;
    font-size: size(16px);
    font-weight: 500;
    line-height: size(20px);
    border-right: 2px solid $color-whisper;
    border-bottom: 2px solid $color-whisper;
    text-align: center;


    &:last-child,
    &:nth-child(5n) {
      border-right: none;
    }

    @include viewport--md {
      width: 50%;
      min-height: 129px;
      padding: 0 10px;
      border-bottom: 2px solid $color-whisper;

      &:nth-child(2n - 1) {
        border-right: none;
      }
    }

    @include viewport--sm {
      min-width: unset;
      min-height: 98px;
      padding: 0 20px;
    }

    .page__tags--about & {
      padding: size(39px) 0 size(30px);
      width: 24.3%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;


      &:nth-child(4n - 2),
      &:nth-child(4n - 1) {
        width: 25.7%;
      }

      span {
        padding: 0 size(20px);
        font-size: size(16px);
        font-weight: 400;
        line-height: size(20px);
        text-align: center;
        color: $color-nobel;
      }

      @include viewport--md {
        width: 50%;
        min-height: 129px;
        padding: 18px 10px 0;
        border-bottom: 2px solid $color-whisper;
        border-right: none;

        &:nth-child(4n - 2),
        &:nth-child(4n - 1) {
          width: 50%;
        }

        &:nth-child(2n - 1) {
          border-right: none;
        }

        &:nth-child(4n),
        &:nth-child(4n - 2) {
          border-left: 2px solid $color-whisper;
        }

        span {
          font-size: 12px;
        }
      }

      @include viewport--sm {
        min-width: unset;
        min-height: 124px;
        padding: 17px 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:nth-child(4n),
        &:nth-child(4n - 1) {
          padding-top: 30px;
          padding-bottom: 27px;
        }

        span {
          padding: 0;
          line-height: 16px;
        }
      }
    }
  }

  &__tags-title {
    font-size: size(50px);
    font-weight: 600;
    line-height: size(60px);
    margin-bottom: size(11px);

    @include viewport--md {
      font-size: 40px;
      margin-bottom: 8px;
    }

    @include viewport--sm {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 10px;
    }
  }

  &__tags-list {
    width: 34.38%;
    min-height: 125px;
    font-family: $font-family--main;
    padding: size(33px) size(32px) size(32px) 0;
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);
    border-right: 2px solid $color-whisper;
    border-bottom: 2px solid $color-whisper;

    @include viewport--md {
      min-height: unset;
      padding: 0 0 27px;
      text-align: center;
      font-weight: 500;

      width: 100%;
      border-bottom: 2px solid $color-whisper;
      border-right: 0;
    }

    @include viewport--sm {
      min-height: unset;
      font-size: 16px;
      line-height: 24px;
      padding: 25px 0 23px;
    }

    a {
      color: $color-orange;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-silver;
        }
      }
    }


    &--medium {
      font-weight: 500;
    }
  }

  &__promo {
    display: flex;
    padding-top: size(20px);
    padding-bottom: size(244px);

    @include viewport--md {
      padding-bottom: 235px;
    }

    @include viewport--sm {
      padding-bottom: 128px;
    }
  }

  &__promo-text {
    width: calc(100% - 312px);
    max-width: size(894px);
    font-family: $font-family--main;
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);

    @include viewport--md {
      width: 100%;
    }

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__promo-nav {
    width: size(312px);
    margin-left: auto;

    @include viewport--md {
      display: none;
    }
  }

  &__promo-list {
    list-style: none;
    margin: 0;
    padding: size(36px) size(30px) size(38px) size(37px);
    border: 2px solid $color-whisper;
  }

  &__promo-item {
    margin-bottom: size(32px);
    font-size: size(20px);
    font-weight: 600;
    line-height: size(24px);

    &:last-child {
      margin-bottom: 0;
    }

    a {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-silver;
        }
      }
    }
  }

  &__promo-title {
    margin: size(4px) 0 size(32px);
    font-size: size(32px);
    font-weight: 600;
    line-height: size(36px);
  }

  &__task {
    display: flex;
    padding-top: size(20px);
    padding-bottom: size(221px);

    @include viewport--md {
      flex-direction: column;
      padding-bottom: 223px;
    }

    @include viewport--sm {
      padding-bottom: 136px;
    }

    &--about {
      padding-bottom: size(163px);

      @include viewport--md {
        padding-bottom: 239px;
      }

      @include viewport--sm {
        padding-top: 103px;
        padding-bottom: 132px;
      }
    }
  }

  &__task-title {
    margin: 0 0 size(70px);
    font-size: size(50px);
    font-weight: 600;
    line-height: size(60px);

    @include viewport--sm {
      margin: 0 0 51px;
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__task-main {
    width: 51.3%;
    padding-right: 20px;

    @include viewport--md {
      width: 100%;
      margin-bottom: 51px;
    }

    @include viewport--sm {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__task-text {
    font-size: size(16px);
    font-weight: 400;
    line-height: size(24px);
    @include viewport--sm {
      font-size: size(20px);
      line-height: size(28px);
    }
  }

  &__task-cards {
    padding-top: size(8px); //size(5px);
    width: 48.6%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 65px 39px;

    @include viewport--md {
      padding-top: 5px;
      width: 100%;
      gap: 61px 34px;
    }

    @include viewport--sm {
      grid-template-columns: 1fr;
      gap: 36px;
    }
  }

  &__task-card {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: -45px;
      width: 1.875rem;
      height: 2rem;
      background: url(../../build/img/svg/arrow-right-orange.svg) no-repeat center;
      background-size: 100%;
      background-position: top center;
    }

    @include viewport--md {
      &::before {
        height: 1rem;
        width: 1rem;
        left: -23px;
      }
    }


    @include viewport--sm {
      &::before {
        height: 1.5rem;
        width: 1.5rem;
        left: 0;
      }

      &:nth-child(1) {
        grid-row: 1;
      }

      &:nth-child(2) {
        grid-row: 3;
      }

      &:nth-child(3) {
        grid-row: 2;
      }

      &:nth-child(4) {
        grid-row: 4;
      }
    }
  }

  &__task-name {
    margin: 0 0 size(28px);
    font-size: size(32px);
    font-weight: 400;
    line-height: size(36px);

    @include viewport--md {
      margin: 0 0 23px;
    }

    @include viewport--sm {
      margin: 0 0 20px 30px;
    }
  }

  &__task-content {
    max-width: size(544px);
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);

    @include viewport--md {
      max-width: unset;
    }

    @include viewport--sm {
      font-size: size(20px);
      line-height: size(28px);
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding-top: size(20px);
    padding-bottom: size(159px);

    @include viewport--md {
      padding-bottom: 210px;
      flex-direction: column;
    }

    @include viewport--sm {
      padding-bottom: 130px;
    }

    &--close {
      padding-bottom: size(124px);

      @include viewport--md {
        padding-bottom: 205px;
      }

      @include viewport--sm {
        padding-bottom: 124px;
      }
    }

    &--close2 {
      padding-bottom: size(124px);

      @include viewport--md {
        padding-bottom: 180px;
      }

      @include viewport--sm {
        padding-bottom: 101px;
      }
    }

    &--close3 {
      padding-bottom: size(141px);

      @include viewport--md {
        padding-bottom: 211px;
      }

      @include viewport--sm {
        padding-bottom: 131px;
      }
    }

    &--close4 {
      padding-bottom: size(141px);

      @include viewport--md {
        padding-bottom: 202px;
      }

      @include viewport--sm {
        padding-bottom: 130px;
      }
    }

    &--close5 {
      padding-bottom: size(122px); //size(141px);

      @include viewport--md {
        padding-bottom: 175px;
      }

      @include viewport--sm {
        padding-bottom: 106px;
      }
    }

    &--close6 {
      padding-bottom: size(70px);

      @include viewport--md {
        margin-top: -8px;
        padding-bottom: 200px;

        .page__content-block {
          margin-top: 4px;
          margin-bottom: 28px;
        }
      }

      @include viewport--sm {
        padding-bottom: 26px;

        .page__content-block {
          margin-top: 8px;
        }
      }
    }

    &--min {
      padding-bottom: size(54px);

      @include viewport--md {
        padding-bottom: 32px;
      }

      @include viewport--sm {
        padding-bottom: 7px; //70px;
      }
    }
  }

  &__content-block {
    width: 48.5%;
    margin-right: 3%;
    margin-bottom: size(90px);
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);

    p {
      margin: 0 0 size(24px);

      &.page__content-mt {
        margin-top: size(8px);
      }

      &.page__content-mt2 {
        margin-top: size(4px);
      }

      &.page__content-mt3 {
        margin-top: size(-3px);
      }

      @include viewport--md {
        &.page__content-mt,
        &.page__content-mt2,
        &.page__content-mt3 {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
          padding-right: 5px;
        }
      }

      @include viewport--sm {
        margin: 0 0 20px;
        font-size: 20px;
        line-height: 28px;
      }

      & + .page__inner-subtitle {
        padding-top: size(48px);

        @include viewport--md {
          padding-top: 56px;
        }

        @include viewport--sm {
          padding-top: 36px;
        }
      }

      & > .page__inner-subtitle {
        padding-top: 0;
      }
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    &--wide {
      width: 100%;
      max-width: size(894px);
      margin-top: size(-5px);
      margin-left: auto;
      margin-right: auto !important;

      @include viewport--md {
        max-width: unset;
        margin-right: 0 !important;
        margin: 52px 0 28px;
      }

      @include viewport--sm {
        max-width: unset;
        margin-right: 0 !important;
        margin: 29px 0 24px;
      }
    }

    @include viewport--md {
      width: 100%;
      margin-bottom: 30px; //28px;

      &--mb {
        margin-bottom: 21px;
      }

      &--mb2 {
        margin-bottom: 0;
      }
    }

    @include viewport--sm {
      margin-bottom: 24px;
    }

    & .page__inner-subtitle:first-child {
      margin-top: -3px;
      padding-top: 0;
    }
  }

  &__inner-title {
    margin: 0 0 size(70px);
    font-size: size(50px);
    font-weight: 600;
    line-height: size(60px);
    max-width: size(544px);

    @include viewport--md {
      max-width: unset;
      margin-bottom: 46px;
    }

    @include viewport--sm {
      margin-bottom: 27px;
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__img-wrap {
    margin-top: size(6px);
    width: 100%;
    max-height: size(445px); //  size(450px); ддля графика
    overflow: hidden;

    img {
      width: 100%;
      object-fit: contain;
      object-position: center center;
    }

    @include viewport--md {
      max-height: 464px;
      margin-top: 0; //7px;

      .page__content-block:first-child & {
        margin-top: 7px;
        margin-bottom: 3px;
      }

      .page__content-wrap & {
        position: absolute;
        top: 28px;
        left: 0;
        margin-top: 0;
      }
    }

    @include viewport--sm {
      height: 344px;
      margin-bottom: 3px;

      img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

      .page__content-block:first-child & {
        margin-top: 5px;
      }

      .page__content-wrap & {
        top: 24px;
      }
    }

    &--about {
      margin-top: size(12px);
      max-height: size(456px);
      height: size(456px);

      @include viewport--md {
        height: 449px;
        overflow: hidden;

        img {
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%;
        }

      }

      @include viewport--sm {
        height: 253px;
        margin-top: -4px;;
      }
    }

    &--unlim {
      max-height: unset;
      margin-top: 11px;

      img {
        width: 100%;
      }

      @include viewport--sm {
        //height: 344px;
        margin-top: 0;
        margin-bottom: 3px;
        height: unset;

        img {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &--abs {
      max-height: size(464px);
      margin-top: size(11px);

      img {
        width: 100%;
      }

      @include viewport--md {
        height: 489px;
        max-height: unset;

        img {
          object-fit: cover;
          height: 100%;
        }
      }

      @include viewport--sm {
        height: 228px;
        margin-top: 0;
        margin-bottom: 3px;
      }
    }

    &--mb {
      @include viewport--md {
        margin-top: -10px;
      }

      @include viewport--sm {
        margin-top: -1px;
      }
    }
  }

  &__slider {
    margin-top: size(-4px);
    margin-bottom: size(64px);

    @include viewport--md {
      margin-top: 0;
      margin-bottom: 465px;
      padding-top: 22px;
    }

    @include viewport--sm {
      margin-bottom: 159px;
      padding-top: 0;
    }
  }

  &__blockquote {
    position: relative;
    max-width: size(545px);
    margin: size(-3px) 0 size(20px) auto;
    font-size: size(40px);
    font-weight: 400;
    line-height: size(52px);

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "«";
      position: absolute;
      left: -25px;
      top: 0;
      width: 5px;
      height: 5px;
      font-size: size(40px);
      font-weight: 400;
      line-height: size(52px);
    }

    .page__content-block--wide & {
      margin-top: size(32px);
      max-width: unset;
    }

    @include viewport--md {
      max-width: unset;
      padding-top: 50px;
      padding-left: 20px;

      &::before {
        top: 50px;
        left: 0;
      }

      .page__content-block--wide & {
        &--close {
          margin-top: 0;
          padding-top: 28px;

          &::before {
            top: 27px;
          }
        }
      }
    }

    @include viewport--sm {
      padding-top: 33px;
      padding-left: 8px;
      font-size: 24px;
      line-height: 32px;

      &::before {
        content: "«";
        position: absolute;
        left: -3px;
        top: 33px;
        width: 5px;
        height: 5px;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
      }

      .page__content-block--wide & {
        margin-top: -9px;
        padding-top: 0;

        &::before {
          top: 0;
        }
      }
    }
  }

  &__feedback {
    padding-top: size(20px);
    padding-bottom: size(129px); //size(159px);
    display: flex;
    flex-wrap: wrap;

    @include viewport--md {
      flex-direction: column;
    }

    @include viewport--sm {
      padding-bottom: 103px;
    }
  }

  &__feedback-text {
    width: calc(100% - 310px);
    margin-bottom: size(23px);
    padding-right: size(20px);
    font-size: size(32px);
    font-weight: 400;
    line-height: size(44px);

    @include viewport--md {
      width: 100%;
      padding-right: 0;
      margin-bottom: 91px;
    }

    @include viewport--sm {
      margin-bottom: 33px;
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__feedback-picture {
    position: relative;
    width: 310px;
    height: 310px;
    margin-top: size(-10px);
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }

    @include viewport--md {
      margin-bottom: 32px;
    }

    @include viewport--sm {
      width: 170px;
      height: 170px;
      margin-bottom: 12px;
    }
  }

  &__feedback-position {
    margin-bottom: size(90px);
    font-size: size(16px);
    font-weight: 400;
    line-height: size(24px);

    @include viewport--sm {
      max-width: 167px;
      margin-bottom: 33px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  &__same-list {
    width: 100%;
    margin: 0;
    padding-left: 0;
    padding-top: size(20px);
    padding-bottom: size(63px);

    display: flex;
    flex-wrap: wrap;
    list-style: none;

    @include viewport--md {
      flex-direction: column;
      padding-bottom: 138px;
    }

    @include viewport--sm {
      padding-bottom: 114px;
    }
  }

  &__content-list {
    margin: -3px 0 0;
    padding: 0;
    list-style: none;

    font-family: $font-family--main;
    font-size: size(24px);
    font-weight: 400;
    line-height: size(48px);

    &--mt {
      margin-top: 0;
    }

    @include viewport--md {
      margin-top: -14px;
      padding-left: 32px;
    }

    @include viewport--sm {
      margin-top: 3px;
      padding-left: 14px;
      font-size: 20px;
      line-height: 28px; //32px;
    }
  }

  &__content-item {
    position: relative;
    margin-bottom: 0;

    &::before {
      content: "";
      position: absolute;
      top: 20px;
      left: -28px;
      width: size(14px);
      height: size(13px);
      background: url("../../build/img/svg/arrow-right-orange.svg") no-repeat center;

      @include viewport--sm {
        top: 8px;
        left: -16px;
        width: 9px;
        height: 9px;
        background-size: contain;
      }
    }

    @include viewport--sm {
      margin-bottom: 7px;
    }
  }

  &__square-list {
    margin: size(-11px) 0 0; //size(-4px) 0 0;
    padding: 0;
    list-style: square;

    font-family: $font-family--main;
    font-size: size(24px);
    font-weight: 400;
    line-height: size(48px);

    @include viewport--md {
      margin-top: -29px;
      padding-left: 23px;
    }

    @include viewport--sm {
      margin-top: -2px;
      padding-left: 12px;
      font-size: 16px;
      line-height: 25px; //32px;
    }
  }

  &__square-item {
    position: relative;

    &::marker {
      display: none;
      color: $color-transparent;
    }

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 46%; //22px;
      width: 6px;
      height: 6px;
      background-color: $color-default-black;
    }

    @include viewport--sm {
      margin-bottom: 6.5px;

      &::before {
        left: -12px;
        top: 9px;
        width: 4px;
        height: 4px;
      }
    }
  }

  &__other {
    padding-top: size(27px);
    padding-bottom: size(113px);

    @include viewport--md {
      padding-bottom: 146px;
    }

    @include viewport--sm {
      padding-top: 23px;
      padding-bottom: 124px;
    }
  }

  &__other-post {
    padding-top: size(27px);
    padding-bottom: size(113px);

    @include viewport--md {
      padding-bottom: 162px;
    }

    @include viewport--sm {
      padding-top: 23px;
      padding-bottom: 127px;
    }
  }

  &__other-list {
    position: relative;
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: $color-whisper;
    }
  }

  &__other-item {
    width: 25.76%;
    padding: size(36px) size(34px) size(36px) size(40px);
    font-family: $font-family--main;
    font-size: size(20px);
    font-weight: 600;
    line-height: size(24px);

    border-right: 2px solid $color-whisper;
    border-bottom: 2px solid $color-whisper;

    &:nth-child(4n + 1) {
      width: 22.7%;
      padding-left: 0;
      padding-right: size(36px);

      @include viewport--md {
        width: 50%;
      }
    }

    &:nth-child(4n) {
      border-right: none;
    }

    @include viewport--md {
      width: 52.5%;
      padding: 37px 34px 27px 40px;

      &:nth-child(2n) {
        border-right: none;
      }

      &:nth-child(2n + 1) {
        padding-left: 0;
        padding-right: size(36px);
        width: 47.5%;
      }
    }

    @include viewport--sm {
      width: 100%;
      padding: 40px 34px 39px 40px;

      &:nth-child(2n) {
        padding-left: 0;
      }

      &:nth-child(2n + 1) {
        width: 100%;
        border-right: none;
      }
    }
  }

  &__footer {
    padding-bottom: size(228px);

    @include viewport--md {
      margin-top: -235px;
    }

    @include viewport--sm {
      margin-top: -22px;
      padding-bottom: 77px;
    }
  }

  &__footer-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: size(7px);
    padding: size(35px) 0 size(12px);
    font-size: size(24px);
    font-weight: 500;
    line-height: size(32px);
    border-bottom: 4px solid $color-whisper;

    @include viewport--md {
      margin-top: 13px;
      margin-bottom: 7px;
      padding-bottom: 6px;
      font-size: 16px;
      line-height: 20px;
      border-bottom: 2px solid $color-whisper;
    }

    @include viewport--sm {
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 19px;
      padding-top: 33px;
      padding-bottom: 13px;
    }
  }

  &__footer-tags {
    max-width: 90%;
    margin: 0;
    padding: 0;

    list-style: none;

    @include viewport--md {
      margin-left: 5px;
    }

    @include viewport--sm {
      margin-bottom: 12px;
      margin-left: 0;
      line-height: 24px;
    }
  }

  &__footer-tag {
    display: inline-block;


    @include viewport--sm {
      display: inline;
    }
  }

  &__footer-link {
    color: $color-orange;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-silver;
      }
    }
  }

  &__date {
    font-weight: normal;
    font-size: size(24px);
    line-height: size(32px);
    text-align: right;

    color: $color-silver;

    @include viewport--md {
      font-size: 16px;
      line-height: 24px;
    }

    @include viewport--sm {
      text-align: left;
    }
  }

  &__share-wrap {
    display: flex;
    align-items: center;
    font-size: size(24px);
    font-weight: 500;
    line-height: size(32px);
    color: $color-silver;

    span {
      margin-right: size(35px);

      @include viewport--sm {
        margin-bottom: 12px;
      }
    }

    @include viewport--md {
      margin-left: 5px;
      font-size: 16px;
      line-height: 20px;
    }

    @include viewport--sm {
      margin-left: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__share-list {
    display: inline-flex;
    padding-left: 0;
    margin: size(12px) 0 0;
    color: $color-silver;
    list-style: none;

    @include viewport--md {
      margin-top: 6px;
    }

    @include viewport--sm {
      margin-left: -1px;
    }
  }

  &__share-item {
    margin-right: size(10px);

    a {
      color: $color-silver;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-orange;
          opacity: 0.7;
        }
      }
    }
  }

  &__back.link-with-line {
    display: none;

    @include viewport--sm {
      display: flex;
      margin-bottom: 168px;
      margin-top: 7px;
    }
  }

  &__content-wrap {
    @include viewport--md {
      position: relative;
      padding-top: 584px;
    }

    @include viewport--sm {
      padding-top: 78.4vw; //294px;
    }
  }

  &__scroll-up.scroll-up {
    bottom: 5%;
  }

  &__paddings {
    padding-bottom: size(88px);

    @include viewport--md {
      margin-top: -43px;
      padding-bottom: 100px;
    }

    @include viewport--sm {
      padding-bottom: 70px;
    }
  }
}
