.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: rgba($color-default-black, 0.6);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: size(60px) size(40px);

    @include viewport--md {
      padding: 0;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: size(740px);

    background-color: $color-default-white;

    @include viewport--md {
      width: 100%;

      min-height: 100vh;
      min-height: calc(100 * var(--vh, 1vh));
    }

    .modal--lang & {
      width: 90%;
      max-width: size(660px);
      margin: auto;

      @include viewport--md {
        min-height: unset;
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: size(36px);
    right: size(32px);

    display: flex;
    outline: none;

    @include viewport--sm {
      top: 23px;
      right: 12px;
    }

    svg {
      .modal--lang & {
        color: $color-default-white;
      }

      @include viewport--md {
        width: 40px;
        height: auto;
      }

      @include viewport--sm {
        width: 25px;
      }
    }

    .modal--lang & {
      top: 1.2rem;
      right: 1.2rem;
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include viewport--sm {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    display: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 2147483648;
  }

  &--review,
  &--partner,
  &--lang {
    transform: scale(1);
    transition: none;
  }
}
