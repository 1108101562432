.contacts {
  padding-top: size(65px);
  margin-bottom: size(271px);
  font-family: $font-family--main;
  color: $color-default-black;

  @include viewport--md {
    margin-bottom: 233px;
  }

  @include viewport--sm {
    padding-top: 41px;
    margin-bottom: 22px;
  }

  &__title {
    margin-bottom: size(67px);

    @include viewport--md {
      //font-size: 60px;
      //line-height: 68px;
      margin-bottom: 69px;
    }

    @include viewport--sm {
      margin-bottom: 40px;
    }
  }

  &__form-wrap {
    //width: 48.5%;
    display: flex;
    flex-direction: row-reverse;

    position: relative;

    @include viewport--md {
      display: block;
      width: 100%;
    }
  }

  &__input-wrap {
    display: none;

    @include viewport--md {
      max-width: 329px;
      display: block;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 90px;
    }

    @include viewport--sm {
      max-width: unset;
      margin-bottom: 55px;
    }
  }

  &__list {
    width: 48.5%;
    margin: 0;
    margin-right: 2.9%;
    margin-top: size(-25px);

    padding: 0;
    list-style: none;

    @include viewport--md {
      width: 100%;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 231px;
    }

    @include viewport--sm {
      margin-bottom: 148px;
    }
  }

  &__list ~ .contacts__office {
    margin-top: 82px;
  }

  &__city {
    font-size: size(32px);
    font-style: normal;
    font-weight: 400;
    line-height: size(44px);
    //color: $color-default-black;

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__accordeon-btn {
    position: relative;
    width: 100%;
    padding: size(23px) 0;
    display: flex;

    background-color: $color-transparent;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      width: size(29px);
      height: size(29px);
      margin-left: auto;
      margin-top: 6px;
      margin-right: 2px;
      color: $color-default-black;
      flex-shrink: 0;
      opacity: 0;
      //transform: rotate(180deg);

      @include viewport--md {
        opacity: 1;
        margin-right: 7px;
      }

      @include viewport--sm {
        margin-top: -5px;
        margin-right: -3px;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }

      &:hover svg,
      &:focus svg {
        opacity: 1;
        color: $color-default-black;
      }
    }

    @include viewport--md {
      display: none;
    }

    @include viewport--sm {
      padding: 18.5px 0 16px;
    }

    &.active {
      color: $color-orange;

      svg {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
  }

  &__item {
    border-bottom: 2px solid $color-whisper;

    /*&.active > .contacts__accordeon-btn {
      color: $color-orange;

      svg {
        opacity: 1;
        transform: rotate(180deg);
      }
    }

    &:not(.active):not(:hover) > .contacts__accordeon-btn {
      color: $color-default-black;

      svg {
        opacity: 0;

        @include viewport--md {
          opacity: 1;
        }
      }
    }*/

    /*&.active > .contacts__content {
     overflow: visible;
    }

    &.active ~ .contacts__map {
      opacity: 1;
    }*/

    @include viewport--md {
      border-bottom: none;
    }
  }

  &__content {
    position: relative;
    padding: 0;
    display: block;
    max-height: 0;
    overflow: hidden;
    pointer-events: auto;

    @include viewport--md {
      max-height: unset;
    }
  }

  &__content-wrap {
    @include viewport--md {
      display: flex;
      flex-direction: column; //column-reverse;
      //padding-right: 38px;
    }

    @include viewport--sm {
      padding-right: 0;
    }
  }

  &__info-list {
    padding: 0;
    list-style: none;

    & ~ .contacts__office {
      margin-top: 82px;
    }
  }

  &__info-item {
    margin-bottom: size(31px);

    @include viewport--md {
      margin-bottom: 30px;
    }

    @include viewport--sm {
      margin-bottom: 20px;
    }
  }

  &__name {
    margin-bottom: size(11px);
    font-size: size(16px);
    font-weight: 500;
    line-height: size(20px);
    color: $color-default-black;

    @include viewport--sm {
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__info {
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);

    a {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-orange;
        }
    }
    }

    @include viewport--md {
      max-width: 630px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__office {
    margin: 0 0 size(31px);
    padding: 0;
    border: none;
    background-color: unset;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    outline: none;
    cursor: pointer;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus,
      &:active {
        color: $color-orange;
      }
    }
  }

  &__map-wrap {
    //position: absolute;
    //top: -58px;
    //left: 106%;
    //width: 100%;
    position: relative;
    width: 48.5%;
    min-height: size(660px);

    opacity: 1;

    @include viewport--md {
      position: relative;
      top: 0;
      left: 0;
      opacity: 1;
      width: 100%;
      min-height: 464px;
      margin-top: 4px;
      margin-bottom: 41px;
    }

    @include viewport--sm {
      margin-top: 0;
      margin-bottom: 28px;
      height: 344px;
      min-height: 0;
    }

  }

  &__map {
    //position: absolute;
    //top: -58px;
    //left: 106%;
    width: 100%;
    height: size(660px);
    background-color: $color-white-smoke;
    //opacity: 0;
    transition: opacity $transition;
    //animation: show-slow 0.3s ease;

    @include viewport--md {
      height: 464px;
    }

    @include viewport--sm {
      height: 344px;
    }

    &.show  {
      animation: show-slow 0.4s ease;
      animation-fill-mode: forwards;
      opacity: 1;
    }


    &.active {
      opacity: 1;
    }
  }


  @keyframes show-slow {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

[class*="ymaps-2-1"][class*="-ground-pane"] {
  filter: grayscale(1);
}
