.clients-filter {
  position: relative;
  padding-top: size(2px);

  @include viewport--md {
    display: flex;
    justify-content: space-between;
  }

  @include viewport--sm {
    display: block;
  }


  &__item {
    margin-bottom: size(33px);

    @include viewport--md {
      flex-basis: 47.5%;
    }

    @include viewport--sm {
      margin-bottom: 50px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn {
    display: flex;
    justify-content: space-between;

    padding: size(5px) 0;

    cursor: pointer;
    border-bottom: size(4px) solid $color-whisper;
    transition: border $transition;

    svg {
      opacity: 0;
      transform: rotate(-90deg);

      @include viewport--md {
        opacity: 1;
      }
    }

    &.active {
      svg {
        opacity: 1;
        transform: rotate(90deg);
      }
    }

    &.tagged {
      .clients-filter__title {
        color: $color-default-black;
      }

      @include viewport--md {
        border-bottom: 4px solid $color-orange;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        svg {
          opacity: 1;
        }
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: size(20px);
    line-height: size(24px);
    color: $color-silver;
  }

  &__aside {
    background-color: $color-default-white;

    @include viewport--md {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: 0;
      opacity: 0;
    }

    &.active {
      position: absolute;

      @include viewport--md {
        opacity: 1;
      }
    }
  }


  &__tag-list {
    margin: 0;
    padding: 0;
    padding: size(38px) 0 size(34px);
    list-style: none;

    @include viewport--md {
      padding-bottom: 60px;
    }

    @include viewport--sm {
      padding-top: 28px;
      padding-right: 38px;
      padding-bottom: 35px;
    }
  }

  &__group-industry {
    @include viewport--md {
      position: relative;
      display: flex;
      justify-content: space-between;

      &::after {
        content: "";
        position: absolute;
        bottom: 24px;
        left: 0;

        width: 100%;
        height: 4px;

        background-color: $color-whisper;
      }
    }

    @include viewport--sm {
      display: block;
      padding-bottom: 30px;

      &::after {
        display: none;
      }
    }

    .clients-filter__tag-list {

      &:last-child {
        padding-top: 0;

        @include viewport--md {
          padding-top: 38px;
        }

        @include viewport--sm {
          padding-top: 0;
        }

      }
    }
  }

  &__tag {
    margin-bottom: size(32px);

    font-weight: 600;
    font-size: size(20px);
    line-height: size(24px);

    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--md {
      margin-bottom: 32px;
      max-width: 98%;
    }

    @include viewport--sm {
      margin-bottom: 28px;
    }

    &--active {
      color: $color-orange;
      user-select: none;
      cursor: auto;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }

      &--active:hover,
      &--active:focus {
        color: $color-orange;
      }
    }
  }

  &__tag-group {
    @include viewport--md {
      position: relative;
      display: flex;
      justify-content: space-between;

      &::after {
        content: "";
        position: absolute;
        bottom: -38px;
        left: 0;

        width: 100%;
        height: 4px;

        background-color: $color-whisper;
      }
    }

    @include viewport--sm {
      display: list-item;

      &::after {
        display: none;
      }
    }
  }

  &__tag-sublist {
    display: grid;

    grid-template-columns: repeat(10, size(20px));
    grid-template-rows: 1fr;
    justify-content: space-between;
    justify-items: center;
    grid-row-gap: size(32px);

    margin: 0;
    padding: 0;
    margin-bottom: size(32px);
    list-style: none;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }


    @include viewport--md {
      flex-basis: 44.5%;
      margin-bottom: 0;

      &:last-child {
        margin-right: 24px;
      }
    }

    @include viewport--sm {
      margin-bottom: 28px;
      grid-row-gap: 28px;
      grid-template-columns: repeat(10, size(12px));

      &:last-child {
        margin-right: 0;
      }
    }

    .clients-filter__tag {
      margin-bottom: 0;
    }
  }


}
