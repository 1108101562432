.main-project {
  padding-top: size(127px);
  padding-bottom: size(272px);

  @include viewport--md {
    padding-top: size(131px);
    padding-bottom: size(268px);
  }

  @include viewport--sm {
    padding-top: size(90px);
    padding-bottom: size(180px);
  }

  &__slider {
    @include viewport--md {
      margin-bottom: 85px;
    }

    @include viewport--sm {
      margin-bottom: 63px;
    }
  }

  &__slider-thumbs.swiper-container {
    position: absolute;
    top: 0;
    right: 0;
    width: size(310px);
    height: calc(100% - #{size(4px)});
    padding: size(38px) size(41px) size(51px) size(38px);
    background-color: $color-default-black;

    @include viewport--md {
      position: relative;
      width: 100%;
      padding: 34px 35px 31px 35px;
    }

    @include viewport--sm {
      padding: 34px 17px 19px 14px;
    }

    & .swiper-slide {
      height: auto !important;
      margin-bottom: size(33px);

      @include viewport--md {
        // width: calc(100% / 2 - 110px);
        margin: 0;
      }

      @include viewport--sm {
        width: 100%;
        margin: initial;
      }

      &:hover {
        cursor: pointer;
      }
    }

    & .swiper-slide-thumb-active {
      .project-slide-thumbs__title {
        color: $color-orange;

        @include viewport--sm {
          color: $color-default-white;
        }
      }
    }

    .swiper-wrapper {
      @include viewport--md {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        gap: size(29px);
        // flex-direction: row;
        // flex-wrap: wrap;
        // margin: 0 -35px;
      }

      @include viewport--sm {
        display: flex;
        width: 100%;
        grid-template-columns: unset;
        grid-template-rows: unset;
        gap: unset;
      }
    }
  }
}
