.custom-input {
  position: relative;
  width: 100%;
  margin-bottom: size(20px);

  @include viewport--sm {
    margin-bottom: 16px;
  }

  input,
  textarea {
    position: relative;
    display: block;
    width: 100%;
    min-height: size(76px);
    padding: size(36px) size(22px) size(14px) ;

    font-family: $font-family--main;
    font-size: size(16px);
    font-weight: 400;
    line-height: size(24px);

    color: $color-default-black;

    background-color: $color-white-smoke;

    border: 1px solid $color-white-smoke;
    outline: none;
    // transition: color $transition, border $transition, background-color $transition;
    caret-color: transparent;


    @include viewport--sm {
      padding: 26px 15px 4px;

      min-height: 56px;
    }

    &:hover {
      color: $color-default-black;
      caret-color: $color-default-black;
    }

    .error & {
      border: 1px solid $color-scarlet;
    }

    .modal-lang & {
      min-height: size(29px);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 50px $color-white-smoke inset !important;
  }

  input:focus + label {
    top: size(15px);
    left: size(22px);
    font-size: size(12px);
    line-height: size(16px);
}

  textarea {
    height: 130px;
  }

  &.error textarea {
    //border: 1px solid $color-flamingo;
  }

  &.error input {
    //min-height: 52px;
    color: $color-scarlet;
  }

  input::placeholder {
    visibility: hidden;
    // color: $color-nobel;
    color: $color-transparent;
  }

  label {
    position: absolute;
    top: size(27px);
    left: size(23px);
    font-family: $font-family--main;
    font-size: size(16px);
    font-weight: 400;
    line-height: size(24px);
    color: $color-nobel;
    // transition: $transition;
    pointer-events: none;
    z-index: 2;

    .modal-lang & {
      left: 0;
      top: size(0px);
      font-size: size(20px);
      font-weight: 600;
      line-height: size(24px);
      color: $color-default-white;
    }

    @include viewport--sm {
      top: 16.5px;
      left: 16px;
    }


  }

  &.error label {
    color: $color-scarlet;
  }

  &--textarea {
    display: flex;
    flex-direction: column;
  }

  &--disable {
    opacity: 0.32;
    pointer-events: none;
  }

  &__error-message {
    visibility: hidden;
    opacity: 0;
    max-height: 0;

    a {
      color: $color-scarlet;
    }

    .custom-input.error & {
      visibility: visible;
      opacity: 1;

      margin-top: size(15px);
      padding-left: size(23px);
      font-family:$font-family--main;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $color-scarlet;

      a {
        color: $color-scarlet;
      }

      @include viewport--sm {
        padding-left: 15px;
      }
    }
  }

  &--has-value {
    input + label {
      top: size(15px);
      left: size(22px);
      font-size: size(12px);
      line-height: size(16px);

      @include viewport--sm {
        top: size(8px);
        left: size(15px);
      }
    }

    &.error {
      input + label {
        color: $color-scarlet;
      }
    }
  }

  &--select {


    .custom-input__dropdown-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 4px;

      // transition: fill 0.3s ease, transform 0.3s ease;

      pointer-events: none;

      fill: #000000;
    }

    input {
      background-color: $color-transparent;
      border: none;
      border-bottom: 4px solid $color-whisper;
      cursor: pointer;
      // transition: border-color $transition, opacity $transition;

      @include viewport--md {
        padding: 0 0 6px 0;
        min-height: 45px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
      }

      .modal-lang & {
        width: size(87px);
        right: 0;
        margin-left: auto;
        padding: 0 0 size(5px);
        font-size: size(20px);
        font-weight: 600;
        line-height: size(24px);
        color: $color-default-white;
        border-bottom: none;
      }

      .modal-lang .modal-lang__input-wrap--region & {
        width: size(95px);
      }

      /*.modal-lang .modal-lang__input-wrap--lang & {
        width: size(105px);
        color: red;
      }*/
    }


    .custom-input__select-list {
      position: absolute;
      top: 100%;
      left: 0;

      width: 100%;
      height: auto; //150px;
      margin: 0;
      padding: 0;
      overflow-y: auto;

      list-style: none;

      background-color: #ffffff;
      visibility: hidden;
      opacity: 0;
      z-index: 100;
      //border: 1px solid #000000;

      // transition: visibility 0.3s ease, opacity 0.3s ease;

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;

      @include viewport--md {
        //position: relative;
        padding: 38px 38px 28px 38px;
        width: 404px;
        left: -35px;

        &::after {
          content: "";
          position: absolute;
          bottom: 28px;
          left: 36px;
          right: 36px;
          height: 4px;
          background-color: $color-whisper;
        }
      }

      @include viewport--sm {
        width: calc(100% + 32px);
        //max-width: 404px;
        min-height: 410px;
        left: -16px;
        padding: 25px 0 28px 0;

        &::after {
          left: 16px;
          right: 16px;
        }

        .modal-lang & {
          min-height: 0;
        }

      }

      .modal-lang & {
        position: relative;
        width: size(90px);
        right: 0;
        left: unset;
        margin-right: 0;
        margin-left: auto;
        color: $color-default-white;
        background-color: $color-default-black;

        @include viewport--md {
          padding: 0;
          width: 87px;
          &::after {
            display: none;
          }
        }

        @include viewport--sm {
          width: 87px;
          left: unset;
          min-height: 0;
        }


      }


    }



    .custom-input__select-item {
      position: relative;
      margin: 0 16px;
      padding: 14px 0;

      font-size: 16px;
      line-height: 20px;
      color: #000000;

      cursor: pointer;

      // transition: color 0.3s ease;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-silver;
        }
      }

      @include viewport--sm {
        padding: 15px 0;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
      }

      &--selected {
        font-weight: 700;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 10px;
          width: 8px;
          height: 8px;
          transform: translateY(-50%);
          border-radius: 50%;
          background-color: #000000;
        }
      }

      .modal-lang & {
        padding: 0;
        margin: 0;
        margin-bottom: size(3px);
        font-size: size(20px);
        line-height: size(24px);
        font-weight: 600;
        color: $color-default-white;

        @media (hover: hover), screen and (min-width: 0\0) {
          &:hover,
          &:focus {
            color: $color-orange;
          }
        }
      }

      &.hidden {
        display: none;
      }
    }

    .custom-input__select-item + .custom-input__select-item {
      //border-top: 1px solid rgba(#000000, 0.2);
    }
    &.custom-input--select-opened input {
      border-bottom: 4px solid $color-orange;

      .modal-lang & {
        border-bottom: none;
      }


    }

    &.modal-lang__input-wrap--lang .custom-input__select-list{
      .modal-lang  & {
        width: size(103px);
      }
    }
  }

  .custom-input__wrapper {
    position: relative;

    svg {
      position: absolute;
      right: -5px;
      top: 5px;
      // transition: transform $transition;

      @include viewport--sm {
        right: -2px;
        top: -3px;
      }

      .modal-lang & {
        width: size(14px);
        height: size(14px);
        right: -7px;
        top: 5px;
      }
    }
  }

  &.custom-input--select-opened .custom-input__wrapper {
    svg {
      transform: rotate(180deg);
    }

  }

  &--select-opened {
    .custom-input__select-list {
      visibility: visible;
      opacity: 1;
    }

    .custom-input__dropdown-icon {
      transform: translateY(-50%) rotate(-180deg);
    }
  }
}
