.main-hero {
  min-height: 100vh;
  min-height: calc(100 * var(--vh, 1vh));
  display: flex;
  justify-content: space-between;

  @include viewport--md {
    flex-direction: column;
    // justify-content: flex-start;
    min-height: 100vh;
  }

  &__img-block {
    width: 64.8%;
    position: relative;

    @include viewport--md {
      width: 100%;
      height: 97vh;
      z-index: 5;
      // height: calc(66.7 * var(--vh, 1vh));
    }

    @include viewport--sm {
      width: 100%;
      height: 66.9vh;
      // height: calc(66.9 * var(--vh, 1vh));
    }
  }

  &__img-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      border: size(4px) solid $color-default-white;
      width: calc(100% - #{size(80px)});
      height: calc(100% - #{size(134px)});
      margin: size(40px) size(40px) size(94px);
      z-index: 3;

      @include viewport--md {
        width: calc(100% - 72px);
        height: calc(100% - 125px);
        margin: 34px 36px 91px;
      }

      @include viewport--sm {
        width: calc(100% - #{size(32px)});
        height: calc(100% - #{size(88px)});
        margin: size(16px) size(16px) size(72px);
      }
    }
  }

  &__img-item {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__bottom-block {
    position: absolute;
    z-index: 2;
    bottom: size(13px);
    margin: 0 size(40px) 0 size(38px);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: calc(100% - #{size(73px)});

    @include viewport--md {
      margin: 0 36px 0 34px;
      width: calc(100% - 68px);
      bottom: 14px;
    }

    @include viewport--sm {
      margin: 0 16px;
      width: calc(100% - 32px);
      bottom: 7px;
    }
  }

  &__bottom-logo {
    top: -1px;
    position: relative;
    height: auto;

    svg {
      width: size(188px);
      height: size(110px);

      @include viewport--sm {
        width: 142px;
        height: 87px;
      }
    }
  }

  &__bottom-text {
    margin-bottom: size(10px);
  }

  &__bottom-text-small {
    margin: 0 0 size(11px);
    font-weight: 500;
    font-size: size(12px);
    line-height: size(14px);
    color: $color-default-white;

    @include viewport--sm {
      font-size: 6px;
      line-height: 9px;
      margin: 0 0 4px;
    }
  }

  &__bottom-text-big {
    margin: 0;
    font-weight: 600;
    font-size: size(32px);
    line-height: size(14px);
    color: $color-default-white;

    @include viewport--sm {
      font-size: 16px;
      line-height: 9px;
    }
  }

  &__content-block {
    width: 35.2%;
    min-width: size(507px);
    position: relative;
    display: flex;
    flex-shrink: 0;
    padding: size(36px) size(39px) size(92px) size(39px);

    @include viewport--md {
      width: 100%;
      min-width: 100%;
      padding: size(25px) size(36px) 0;
    }

    @include viewport--sm {
      padding: 12px 14px 23px;
    }
  }

  &__accordion-btn {
    margin-top: auto;

    @include viewport--md {
      width: 428px;
    }

    @include viewport--sm {
      width: 80%;
    }

    @media (max-width: 370px) {
      width: 95%;
    }

    &.active {
      margin-top: 0;
    }
  }

  &__content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__link-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    @include viewport--md {
      max-width: 80%;
    }

    @include viewport--sm {
      max-width: 100%;
    }
  }

  &__link-item {
    margin-right: size(4px);

    &:last-child {
      margin-right: 0;
    }

    &.active .main-hero__link {
      color: $color-default-black;

      @include viewport--md {
        color: $color-silver2;
      }
    }
  }

  &__link {
    font-size: size(32px);
    line-height: size(44px);
    color: $color-silver2;

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
    }

    span {
      color: $color-default-black;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: $color-default-black;
      }
    }
  }

  &.open {
    z-index: 5;
    position: relative;
    background-color: $color-default-white;

    .main-hero__img-block {
      @include viewport--md {
        height: 47vh;
        // height: calc(47 * var(--vh, 1vh));
      }

      @include viewport--sm {
        // height: 42vh;
        height: 42vh;
        // height: calc(42 * var(--vh, 1vh));
      }
    }

    .main-hero__content-block {
      padding: size(30px) size(39px) size(80px) size(39px);

      @include viewport--md {
        min-height: 53vh;
        padding: 27px 36px 10px;
        // min-height: calc(53 * var(--vh, 1vh));
      }

      @include viewport--sm {
        min-height: 58vh;
        padding: 12px 14px 18px;
        // min-height: 58vh;
        // min-height: calc(58 * var(--vh, 1vh));
      }
    }

    .main-hero__accordion-btn {
      @include viewport--md {
        margin-bottom: 20px;
      }

      @include viewport--sm {
        margin-bottom: 5vh;
      }
    }

    .main-hero__bottom-block {
      @include viewport--md {
        bottom: 10px;
      }

      @include viewport--sm {
        bottom: 7px;
      }
    }

    .main-hero__bottom-text {
      @include viewport--md {
        margin-bottom: 15px;
      }

      @include viewport--sm {
        margin-bottom: 9px;
      }
    }
  }
}
