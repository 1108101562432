.about {
  position: relative;
  max-width: size(1360px);
  margin-top: size(3px);
  max-width: size(1440px);
  margin: 0 auto;
  padding-bottom: size(222px);
  font-family: $font-family--main;
  color: $color-default-black;

  @include viewport--md {
    padding-bottom: 252px;
  }

  @include viewport--sm {
    margin-top: -18px;
    padding-bottom: 106px;
  }

  &__slider {
    position: relative;

    .swiper-container-autoheight .swiper-wrapper {
      transition-property: none !important;
      transition: none !important;
    }
  }

  &__slider-wrap {
    position: absolute;
    top: 0;
    left: size(40px);
    display: flex;

    svg {
      width: size(76px);
      height: size(76px);
      margin-top: size(10px);
      margin-right: size(42px);
    }

    @include viewport--md {
      left: 32px;

      svg {
        width: 86px;
        height: 86px;
        margin-top: 0;
        margin-right: 37px;
      }
    }

    @include viewport--sm {
      align-items: flex-start;
      left: 16px;

      svg {
        width: 44px;
        height: 44px;
        margin-top: 0;
        margin-right: 14px;
      }
    }

    @media (max-width: 350px) {
      svg {
        width: 36px;
        height: 36px;
      }
    }
  }

  &__slide {
    display: flex;
    transition-property: none;
    transition: none !important;


    .swiper-container-fade &,
    .swiper-container-autoheight & {
      transition-property: none !important;
      transition: none !important;
    }

    @include viewport--md {
      flex-direction: column;
    }
  }

  &__text-wrap {
    width: 51.47%;
    padding-left: size(155px);
    padding-right: size(60px);

    @include viewport--md {
      width: 100%;
      margin-bottom: 53px;
    }

    @include viewport--sm {
      padding-left: 74px;
      padding-right: 16px;
      margin-bottom: 32px;
    }
  }

  &__title {
    margin: 0 0 size(22px);
    font-size: size(50px);
    font-weight: 600;
    line-height: size(60px);

    @include viewport--md {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__year {
    font-size: size(104px);
    font-weight: 400;
    line-height: size(104px);
    color: $color-default-black;

    @include viewport--md {
      font-size: 119px;
      line-height: 68px;
      margin-top: 14px;
    }

    @include viewport--sm {
      font-size: 62px;
      line-height: 62px;
      margin-top: -7px;
    }

    @media (max-width: 350px) {
      margin-top: -10px;
      font-size: 49px;
    }
  }

  &__btn {
    width: size(76px);
    height: size(76px);
    top: size(31px);
    bottom: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-default-black;
    background-color: $color-whisper;
    outline: none;

    @include viewport--md {
      width: 86px;
      height: 86px;
      top: 22px
    }

    @include viewport--sm {
      width: 44px;
      height: 44px;
      top: 22px;
    }

    @media (max-width: 350px) {
      width: 36px;
      height: 36px;
      top: 22px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &::after {
      display: none;
    }

    &.swiper-button-prev {
      right: size(160px);
      left: auto;

      svg {
        transform: rotate(90deg);
      }

      @include viewport--md {
        right: 157px;
        bottom: 0;
      }

      @include viewport--sm {
        right: 75px;
      }

      @media (max-width: 350px) {
        right: 65px;
      }
    }

    &.swiper-button-next {
      right: size(40px);

      svg {
        transform: rotate(-90deg);
      }

      @include viewport--md {
        right: 32px;
        bottom: 0;
      }

      @include viewport--sm {
        bottom: 76px;
        right: 16px;
        left: unset;
        border-right: none;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-gainsboro;
      }
    }
  }

  &__img-wrap {
    position: relative;
    width: 48.52%;
    max-width: 660px;
    padding-bottom: size(78px);
    margin-left: auto;
    margin-right: size(40px);

    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }

    .video {
      width: 100%;
      height: 100%;

      video {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    @include viewport--md {
      position: relative;
      margin: 0 32px;
      padding: 0 0 72px;
      width: calc(100% - 64px);
      max-width: 100%;
      height: 536px;

      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

      .video {
        width: 100%;
        height: 100%;

        video {
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }

    @include viewport--sm {
      height: 416px;
      width: calc(100% - 32px);
      margin: 0 auto;

      video {
        max-width: unset;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__thumbs {
    position: absolute;
    margin: 0;
    left: size(273px);
    top: size(104px);
    display: flex;
    padding: size(18px) 0 size(10px) 0;
    max-width: size(76px);
    overflow: visible !important;
    z-index: 2;

    @include viewport--md {
      top: 113px;
      max-width: size(86px);
      left: 36px;
    }

    @include viewport--sm {
      top: 42px;
      left: 76px;
      max-width: 44px;
    }

    .swiper-wrapper {
      transition-duration: 0ms !important;
      transition-property: none !important;
      transition: none !important;
    }


    .swiper-slide {
      transition-property: none !important;
      transition: none !important;
    }
  }

  &__thumbs-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    font-size: size(20px);
    font-weight: 600;
    line-height: size(10px);
    color: $color-whisper;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 2px);
      left: size(77px);
      height: size(4px);
      width: size(40px); //35%;
      background-color: $color-whisper;

      .swiper-slide:last-child & {
        display: none;
      }
    }

    @media (max-width: 800px) {
      &::after {
        left: 86px;
        width: 36px;
      }
    }

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;

      &::after {
        height: 3px;
        width: 16px;
        left: 52px;
      }
    }

    .swiper-slide-thumb-active & {
      color: $color-silver;
    }
  }

  &__main {
    width: 100%;
    margin: 0 auto;
    padding-top: size(201px);
  }

  &__main.swiper-container {
    width: 100%;

    @include viewport--md {
      width: 100%;
    }

    @include viewport--sm {
      padding-top: 140px;
    }
  }

  &__text {
    max-width: size(426px);
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__capture {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    height: size(78px);
    padding: size(22px) size(29px) size(10px);
    font-size: size(24px);
    font-weight: 400;
    line-height: size(32px);
    color: $color-default-white;
    background-color: $color-default-black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include viewport--md {
      height: 72px;
      font-size: 16px;
      line-height: 24px;
      padding: 24px 31px 29px;
    }

    @include viewport--sm {
      padding: 20px 31px 29px;
      font-size: 12px;
      line-height: 16px;
      white-space: normal;
    }
  }
}
