.clients {
  display: flex;
  flex-direction: column;

  padding-top: size(66px);
  padding-bottom: size(264px);

  @include viewport--md {
    padding-top: 70px;
    padding-bottom: 222px;
  }

  @include viewport--sm {
    padding-top: 40px;
    padding-bottom: 180px;
  }

  &__title {
    margin-bottom: size(60px);

    @include viewport--md {
      margin-bottom: 65px;
    }

    @include viewport--sm {
      margin-bottom: 46px;
    }
  }

  &__wrapper {
    display: grid;

    grid-template-columns: size(310px) 1fr;
    grid-template-rows: 1fr;
    gap: 11%;

    @include viewport--md {
      grid-template-columns: 1fr;
      gap: 50px;
    }

    @include viewport--sm {
      gap: 58px;
    }
  }
}
