.feedback-slide {
  display: flex;

  @include viewport--md {
    flex-direction: column;
  }

  &__content {
    width: 74.5%;
    margin-right: size(40px);

    @include viewport--md {
      width: 100%;
      margin-right: 0;
      margin-bottom: 73px;
    }

    @include viewport--sm {
      margin-bottom: 60px;
    }
  }

  &__content-text {
    margin-bottom: size(23px);

    @include viewport--sm {
      margin-bottom: 9px;
    }

    p {
      margin: 0;
      font-size: size(32px);
      line-height: size(44px);

      @include viewport--md {
        font-size: 24px;
        line-height: 32px;
      }

      @include viewport--sm {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__content-user {
    margin: 0;
    font-size: size(16px);
    line-height: size(24px);

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__logo-block {
    display: flex;
    flex-direction: column;
    margin-top: size(8px);

    @include viewport--md {
      margin-top: 0;
    }
  }

  &__logo {
    width: size(310px);
    height: size(310px);
    background-color: $color-whisper;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: size(30px);
    margin-bottom: size(15px);

    @include viewport--md {
      width: 100%;
      height: 332px;
      margin-bottom: 32px;
    }

    @include viewport--sm {
      width: 100%;
      height: 344px;
      margin-bottom: 11px;
    }

    img {
      object-fit: contain;
      object-position: center;
    }
  }

  &__link {
    font-weight: 500;
    font-size: size(16px);
    line-height: size(20px);
    display: flex;
    align-self: center;
    color: $color-orange;

    @include viewport--md {
      align-self: flex-start;
    }

    &:hover {
      color: $color-silver;
    }
  }
}
