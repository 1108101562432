.video {
  height: 100%;
  position: relative;
  outline: none;

  video {
    &::-webkit-media-controls {
      display:none !important;
      opacity: 0;
    }

    &::-webkit-media-controls-panel {
      display: none!important;
      -webkit-appearance: none;
    }

    /* Old shadow dom for play button */

    &::-webkit-media-controls-play-button {
      display: none!important;
      -webkit-appearance: none;
    }

    /* New shadow dom for play button */

    &::-webkit-media-controls-start-playback-button {
      display: none!important;
      -webkit-appearance: none;
    }

    /* Newest shadow dom for play button */

    &::-webkit-media-controls-start-playback-button {
      display: none !important;
    }
  }


  &__container {
    //position: absolute;
    //top: 0;
    //left: 0;
    //width: 100%;
    //height: 100%;
    //font-size: 0;
    //line-height: 0;
    //object-fit: cover;

    object-fit: contain;
    object-position: left top;


  }

  &__play-btn-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 4px);
    //pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index:2147483646;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover .video__play-btn,
      &:focus .video__play-btn {
        color: $color-orange;
      }

      &:hover .video__play-btn[data-state="play"]:not(.native-fullscreen),
      &:focus .video__play-btn[data-state="play"]:not(.native-fullscreen) {
        opacity: 1;

        svg:first-child {
          width: 0;
          height: 0;
          opacity: 0;
        }

        svg:nth-child(2) {
          width: size(60px);
          height: size(60px);
          opacity: 1;

          @include viewport--md {
            width: 50px;
            height:50px;
          }

          @include viewport--sm {
            width: 38px;
            height: 38px;
          }
        }
      }
    }
  }

  &__play-btn {
    width: size(120px);
    height: size(120px);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: auto;
    outline: 0;
    background-color: $color-default-white;
    color: $color-default-black;


    svg {
      width: size(60px);
      height: size(60px);
      z-index: 2147483647;

      &:nth-child(2) {
        width: 0;
        height: 0;
        opacity: 0;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;

      }
    }

    @include viewport--md {
      width: 100px;
      height: 100px;

      svg {
        width: 50px;
        height: 50px;
      }
    }

    @include viewport--sm {
      width: 76px;
      height: 76px;

      svg {
        width: 38px;
        height: 38px;
      }
    }

    &[data-state="pause"] {
      svg {
        width: size(60px);
        height: size(60px);
        z-index: 2147483647;

        &:nth-child(2) {
          width: 0;
          height: 0;
          opacity: 0;
        }
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-orange;
        }
      }

      @include viewport--md {
        width: 100px;
        height: 100px;

        svg {
          width: 50px;
          height:50px;
        }
      }

      @include viewport--sm {
        width: 76px;
        height: 76px;

        svg {
          width: 38px;
          height: 38px;
        }
      }
    }

    &[data-state="play"]:not(.native-fullscreen) {
      opacity: 0;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          opacity: 1;

        }
      }

      svg:first-child {
        width: 0;
        height: 0;
        opacity: 0;
      }

      svg:nth-child(2) {
        width: size(60px);
        height: size(60px);
        opacity: 0;

        @include viewport--md {
          width: 50px;
          height: 50px;
        }

        @include viewport--sm {
          width: 38px;
          height: 38px;
        }
      }
    }
  }

  &__progress-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;

    display: block;
    width: 100%;
    border: none;
    color: $color-default-black;
    box-shadow: none;
    cursor: pointer;

    z-index: 2147483646;

    &::-moz-progress-bar {
      background-color: $color-orange;
    }

    &::-webkit-progress-value {
      background-color: $color-orange;
    }

    span {
      width: 0%;
      height: 100%;
      display: inline-block;
      background-color: $color-orange;
      cursor: pointer;
    }
  }

  &__btns-wrap {
    position: absolute;
    top: size(20px);
    right: size(20px);
    display: flex;
    justify-content: flex-end;
  }

  &__mute {
    position: relative;
    //position: relative;
    //top: size(20px);
    //right: size(96px);
    width: size(60px);
    height: size(60px);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: 0;
    background-color: $color-default-white;
    color: $color-default-black;
    z-index:2147483646;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }

    svg {
      width: size(20px);
      height: size(20px);

      &:nth-child(2) {
        width: 0;
        height: 0;
        opacity: 0;
      }
    }

    &[data-state="unmute"] {
      svg {
        width: size(20px);
        height: size(20px);

        &:nth-child(2) {
          width: 0;
          height: 0;
          opacity: 0;
        }
      }
    }

    &[data-state="mute"] {
      svg:first-child {
        width: 0;
        height: 0;
        opacity: 0;
      }

      svg:nth-child(2) {
        width: size(20px);
        height: size(20px);
        opacity: 1;
      }
    }
  }

  &__fullscreen {
    position: relative;
    margin-left: size(16px);
    //position: absolute;
    //top: size(20px);
    //right: size(20px);
    width: size(60px);
    height: size(60px);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: 0;
    background-color: $color-default-white;
    color: $color-default-black;
    z-index: 2147483646;


    svg {
      width: size(20px);
      height: size(20px);

      &:nth-child(2) {
        width: 0;
        height: 0;
        opacity: 0;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }

    .video[data-fullscreen="true"] & {
      svg:first-child {
        width: 0;
        height: 0;
        opacity: 0;
      }

      svg:nth-child(2) {
        width: size(20px);
        height: size(16px);
        opacity: 1;
      }
    }
  }
}
