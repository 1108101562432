.cases {
  display: flex;
  flex-direction: column;

  padding-top: size(65px);
  padding-bottom: size(260px);

  @include viewport--md {
    padding-top: 55px;
    padding-bottom: 265px;
  }

  @include viewport--sm {
    padding-top: 21px;
    padding-bottom: 168px;
  }

  &__head {
    display: grid;
    grid-template-columns: minmax(70%, auto) size(310px);
    grid-template-rows: minmax(size(172px), auto);
    grid-auto-rows: minmax(size(172px), auto);
    gap: size(40px);

    @include viewport--md {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(20px, auto);
      grid-auto-rows: minmax(20px, auto);
      gap: 20px;
    }

    @include viewport--sm {
      gap: 10px;
    }
  }

  &__title-lg {
    @include viewport--sm {
      display: none;
    }
  }

  &__title-sm {
    display: none;

    @include viewport--sm {
      display: inline;
    }
  }

  &__breadcrumbs {
    @include viewport--md {
      grid-row-start: 1;
    }
  }

  &__list {
    margin: 0;
    padding: 0;

    list-style: none;
  }
}
