.show-more {
  margin-top: size(92px);
  display: flex;
  justify-content: center;

  @include viewport--md {
    display: none;
  }

  button {
    color: $color-orange;
  }
}
