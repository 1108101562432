.search-result {
  display: none;
  padding: size(85px) size(114px) size(85px);
  background: $color-default-white;
  border: 2px solid $color-whisper;
  max-width: size(1360px);
  width: calc(100% - #{size(80px)});

  @include viewport--md {
    padding: 85px 0;
    border: none;
    max-width: 100%;
    width: calc(100% - 64px);
  }

  @include viewport--sm {
    padding: 60px 0 58px;
    width: calc(100% - 32px);
  }

  &.open {
    display: block;
    position: absolute;
    top: 100%;
    z-index: 2147483647;

    &.search-result--header {
      @include viewport--md {
        top: 76px;
        width: calc(100% - 72px);
      }

      @include viewport--sm {
        top: 56px;
        width: calc(100% - 32px);
      }
    }
  }

  &--header {
    margin-top: size(30px);

    &.search-result--success {
      overflow-y: auto;
      height: calc(100vh - #{size(147px)});
    }
  }

  &--error {
    width: size(660px);
    transform: translateX(-50%);
    left: 50%;
    padding: size(34px) size(24px);

    @include viewport--md {
      padding: 34px 32px;
      border: none;
    }

    @include viewport--sm {
      padding: 34px 16px;
      border: none;
    }
  }

  &__text {
    font-size: size(16px);
    line-height: size(24px);
    color: $color-silver;
    margin: 0;
  }

  &__item {
    display: flex;
    margin-bottom: size(88px);

    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--md {
      flex-direction: column;
      margin-bottom: 83px;
    }

    @include viewport--sm {
      margin-bottom: 54px;
    }
  }

  &__left {
    width: size(160px);
    margin-right: size(75px);
    flex-shrink: 0;
  }

  &__cases-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: size(-35px);

    @include viewport--md {
      justify-content: space-between;
    }
  }

  &__cases-item {
    width: size(310px);
    margin-right: size(40px);
    margin-bottom: size(35px);

    @include viewport--md {
      margin-right: 0;
      width: calc(50% - 18px);
    }

    @include viewport--sm {
      width: 100%;
      margin-bottom: 28px;
    }
  }

  &__title {
    font-size: size(24px);
    line-height: size(32px);
    color: $color-silver;
    margin: 0;

    @include viewport--md {
      flex-direction: column;
      margin-bottom: 28px;
    }

    @include viewport--sm {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 26px;
    }
  }

  .case {
    display: flex;

    &:last-child {
      margin-bottom: size(35px);
    }


    &__tags {
      display: none;
    }

    &__link {
      position: relative;

      img {
        width: size(310px);
        height: size(208px);

        @include viewport--md {
          width: 100%;
          height: size(224px);
          max-height: 224px;
        }
      }

      &:hover {
        &::after {
          content: "";
          position: absolute;
          width: size(310px);
          height: size(208px);
          top: 0;
          left: 0;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));

          @include viewport--md {
            content: none;
          }
        }
      }
    }

    &__title {
      font-weight: 600;
      font-size: size(20px);
      line-height: size(24px);
      margin-top: size(17px);

      @include viewport--md {
        width: 100%;
      }

      @include viewport--sm {
        margin-top: 12px;
      }
    }
  }

  .blog-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: size(-35px);

    @include viewport--md {
      justify-content: space-between;
    }

    &__item {
      width: size(310px);
      margin-right: size(40px);
      margin-bottom: size(35px);
      display: flex;
      flex-direction: column;

      @include viewport--md {
        margin-right: 0;
        width: calc(50% - 18px);
      }

      @include viewport--sm {
        width: 100%;
        margin-bottom: 28px;
      }
    }

    &__content {
      width: 100%;
      margin-left: 0;
      padding: 0;
      border-bottom: none;
    }

    &__image {
      max-width: 100%;
      width: size(310px);
      height: size(208px);
      position: relative;

      @include viewport--md {
        width: 100%;
        height: 224px;
        padding-bottom: 224px;
        margin-bottom: 0;
      }

      img {
        @include viewport--md {
          width: 100%;
        }
      }

      &:hover {
        &::after {
          content: "";
          position: absolute;
          width: size(310px);
          height: size(208px);
          top: 0;
          left: 0;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));

          @include viewport--md {
            content: none;
          }
        }
      }
    }

    &__text {
      display: none;
    }

    &__footer {
      display: none;
    }

    &__title {
      font-weight: 600;
      font-size: size(20px);
      line-height: size(24px);
      margin-top: size(17px);
      margin-bottom: 0;
      width: 100%;

      @include viewport--sm {
        margin-top: 12px;
      }
    }
  }
}
