.header {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  min-height: size(110px);
  // transition: top 0.01s linear;

  @include viewport--sm {
    min-height: 75px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    max-width: size(1440px);
    padding: size(38px) size(40px) 0;
    margin: 0 auto;

    @include viewport--md {
      padding: 40px 36px 0;
      align-items: flex-start;
    }

    @include viewport--sm {
      padding: 15px 16px 0;
    }
  }

  &__logo {
    height: size(70px);

    svg {
      width: size(270px);
      height: size(70px);
    }

    @include viewport--sm {
      svg {
        width: 209px;
        height: 53px;
      }
    }
  }

  &__toggle.btn {
    display: none;

    @include viewport--md {
      display: flex;

      width: 49px;
      height: 48px;

      svg {
        width: 49px;
        height: 48px;
      }

      svg:first-child {
        display: flex;
      }

      svg:last-child {
        display: none;
      }
    }

    @include viewport--sm {
      padding: 0;

      width: 32px;
      height: 32px;

      svg {
        width: 32px;
        height: 32px;
      }

      svg:last-child {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__close.btn {
    display: none;
  }

  &.open {
    position: fixed;
    width: 100vw;
    height: 100vh;
    height: calc(100 * var(--vh, 1vh));
    left: 0;
    top: 0;
    background-color: $color-default-white;
    z-index: 2147483647;
    padding: size(38px);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include viewport--md {
      padding: 0;
    }

    &.container {
      max-width: 100%;
    }

    .header__logo {
      display: none;
    }

    .header__wrapper {
      height: 100%;
      padding: 0;
      max-width: calc(#{size(1440px)} - #{size(80px)});
      overflow-x: hidden;

      @include viewport--md {
        min-height: 100vh;
        min-height: calc(100 * var(--vh, 1vh));
        overflow-x: hidden;
        max-width: calc(100% - 64px);
      }
    }

    .header__close.btn {
      display: inline-flex;
      position: absolute;
      top: size(-7px);
      right: size(-7px);

      @include viewport--md {
        display: none;
      }

      svg {
        color: $color-default-black;
        width: size(50px);
        height: size(50px);
      }

      &:hover {
        svg {
          color: $color-silver;
        }
      }
    }
  }

  &--opened {
    position: fixed;
    width: 100%;
    height: 100%;
    min-height: calc(100 * var(--vh, 1vh));
    background-color: $color-default-white;
    left: 0;
    top: 0;
    z-index: 2147483648;
    padding: 29px 36px;

    @include viewport--md {
      padding: 29px 36px;
      min-height: unset;
    }

    @include viewport--sm {
      padding: 15px 16px;
    }

    @include viewport--sm {
      overflow-x: visible;
    }

    .header__logo {
      display: none;
    }

    .header__wrapper {
      width: 100%;
      height: 100%;
      align-items: flex-start;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0;

      @include viewport--md {
      }
    }

    .header__toggle.btn {
      display: flex;
      position: absolute;
      top: 44px;
      right: 30px;

      @include viewport--md {
        width: 49px;
        height: 48px;
        top: 15px;
        right: -6px;
      }

      @include viewport--sm {
        top: 7px;
        right: -5px;
      }

      svg:first-child {
        display: none;
        color: $color-default-black;
      }

      svg:last-child {
        display: flex;
        color: $color-default-black;
      }
    }

    &.open {
      .header__wrapper {
        max-width: unset;
      }

      .header__toggle.btn {
        @include viewport--md {
          top: 44px;
          right: 30px;
        }

        @include viewport--sm {
          top: 22px;
          right: 11px;
        }
      }

      .header__bottom {
        display: none;
      }
    }
  }

  &--main {
    position: absolute;
    z-index: 4;
  }

  &--hero {
    position: absolute;
    right: 0;
    width: 35.2%;
    min-width: size(507px);
    padding: size(36px) size(40px) 0;

    @include viewport--md {
      width: auto;
      min-width: unset;
      position: fixed;
      bottom: 12px;
      right: 16px;
      padding: 58px 20px 28px;
      background-color: $color-default-white;
      min-height: unset;
    }

    @include viewport--sm {
      padding: 15px 16px;
      bottom: -1px;
      right: -2px;
    }

    .header__wrapper {
      padding: 0;
    }

    .header__logo {
      display: none;
    }

    .header__toggle.btn {
      @include viewport--md {
        width: 36px;
        height: 36px;
      }

      @include viewport--sm {
        padding: 0;
      }
    }

    &.header--opened {
      height: 100%;
      width: 100%;
      bottom: 0;
      right: 0;

      @include viewport--md {
        padding: 30px 36px;
        z-index: 6;
      }

      @include viewport--sm {
        padding: 15px 16px;
      }

      .header__toggle.btn {
        @include viewport--md {
          width: 49px;
          height: 48px;
        }
      }
    }

    &.header--opened.open {
      @include viewport--md {
        padding: 0;
      }
    }

    &.open.container {
      max-width: 100%;

      @include viewport--md {
        padding: 0;
      }
    }
  }

  &--fixed {
    position: fixed;
    z-index: 2147483647;
    width: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    max-width: 100%;
    top: 0;
    padding: 0;
    background-color: $color-default-white;

    .header__wrapper {
      max-width: size(1440px);
      padding: size(38px) size(40px) 0;
      margin: 0 auto;
    }

    .header__logo {
      display: flex;
    }

    &.open {
      transform: translateX(0);

      .header__wrapper {
        padding: 0;
      }
    }
  }
}
