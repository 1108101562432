.modal-lang {
  width: 100%;
  //width: 90%;
  //max-width: size(660px);
  position: relative;
  //top: 50%;
  //left: 50%;
  padding: size(35px) size(41px) size(35px) size(39px);
  font-family: $font-family--main;
  color: $color-default-white;
  background-color: $color-default-black;
  //transform: translate(-50%, -50%);

  &__text {
    width: 80%;
    margin-bottom: size(30px);
    font-size: size(16px);
    font-weight: 400;
    line-height: size(24px);
  }

  &__line {
    display: flex;
    align-items: flex-start;
    //margin-bottom: size(56px);
    margin-bottom: size(16px);


    &:last-child {
      margin-bottom: 0;
    }

    @include viewport--sm {
      flex-direction: column-reverse;
      margin-bottom: 6px;
    }

    &--row {
      @include viewport--sm {
        flex-direction: row;
      }
    }
  }

  &__btn {
    padding: 0;
    margin-right: 34px;
    font-size: size(20px);
    font-weight: 600;
    line-height: size(24px);
    color: $color-default-white;
    background-color: $color-default-black;
    border: none;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-orange;
      }
    }

    &--reset {
      color: $color-dim-gray;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-default-white;
        }
      }
    }

    @include viewport--sm {
      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 350px) {
      margin-right: 19px;
    }

  }

  &__input-wrap {
    max-width: size(195px);
    margin-right: size(43px);

    &--region {
      max-width: size(225px); //size(180px);

      .custom-input--select input {
        width: size(85px);
      }



      @include viewport--sm {
        width: 100%;
        max-width: 208px;
      }
    }

    &--lang {
      max-width: size(249px); //size(180px);

      .custom-input--select input {
        width: size(95px);
        color: red;
      }

      .custom-select__list {
        @include viewport--sm {
          min-height: 54px;
        }
      }

      @include viewport--sm {
        max-width: 208px;
      }
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }

    @include viewport--sm {
      margin-right: 0;
    }
  }

  .modal-lang__input-wrap--region.custom-input--select input {
    width: size(129px); //95px;
  }

  .modal-lang__input-wrap--lang.custom-input--select input {
    width: size(103px);
  }

  .modal-lang__input-wrap--region.custom-input--select .custom-input__select-list {
    width: size(127px);
    //max-height: size(50px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
      }
  }

}
