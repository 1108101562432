.breadcrumbs {
  margin: 0;
  margin-top: size(18px);
  padding: 0;

  list-style: none;

  &__item {
    display: inline;
    color: $color-nobel;
  }

  &__link {
    font-weight: 500;
    font-size: size(16px);
    line-height: size(20px);

    color: $color-nobel;

    @include viewport--sm {
      font-size: 12px;
      line-height: 16px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-default-black;
      }
    }

    &--current {
      pointer-events: none;
    }
  }
}
